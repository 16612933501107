<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Direct Duty Requests</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="grid grid-cols-12 gap-x-2 mt-5">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="input-quantity" class="form-label">Duty Code</label>
                <input id="input-quantity" class="form-control" placeholder="Duty Code" autocomplete="off" v-model="duty_filters.duty_code" type="text">
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="pos-form-2" class="form-label">Select Material</label>
                <TomSelect v-model="duty_filters.product_id" :options="{
                placeholder: 'Select Material'
              }" class="w-full" name="Material" id="pos-form-2" @change="materialDetails">
                    <option value="0">Select Material</option>
                    <option v-for="item in pagedata.product_details" :key="item.id" :value="item.id">{{ item.product_name}}</option>
                </TomSelect>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="pos-form-2" class="form-label">Select Specification</label>
                <TomSelect v-model="duty_filters.material_id" :options="{
                placeholder: 'Select Specification'
              }" class="w-full" name="Specification" id="pos-form-2">
                    <option value="0">Select Specification</option>
                    <option v-for="item in pagedata.material_details" :key="item.id" :value="item.id">{{ item.description}}</option>
                </TomSelect>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="pos-form-2" class="form-label">Material Type</label>
                <TomSelect v-model="duty_filters.product_type" :options="{
                placeholder: 'Select Material Type'
              }" class="w-full" name="Material Type" id="pos-form-2">
                    <option value="0">Select Material Type</option>
                    <option value="1">Poly</option>
                    <option value="2">MBB</option>
                    <option value="3">Both</option>
                </TomSelect>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="pos-form-2" class="form-label">Status</label>
                <TomSelect v-model="duty_filters.status" :options="{
                placeholder: 'Select Status'
              }" class="w-full" name="Status" id="pos-form-2">
                    <option value="0">Select Status</option>
                    <option value="1">Pending</option>
                    <option value="2">OOC Pending</option>
                    <option value="3">Rejected</option>
                    <option value="4">Accepted</option>
                </TomSelect>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1">
                <div class="mt-5">
                    <button @click="resetStockFilters" class="btn btn-secondary w-full mt-2">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1">
                <div class="mt-5">
                    <button @click="initTabulator" class="btn btn-primary w-full mt-2">
                        Generate
                    </button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

    <!--- BEGIN: Purchase Details Model --->
    <div id="dta_purchase-details-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Purchase No : {{ pagedata.purchase_item.dta_code }}</h2>
                        <div>
                            <a data-dismiss="modal" href="javascript:;">
                                <XIcon class="w-8 h-8 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body p-10">

                    <table class="w-full border mt-3">

                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Gst Invoice No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Gst Invoice Date</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Company Name</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Import Station</th>
                        </tr>
                        <tr class="">
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.gst_invoice_no }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.gst_invoice_date }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.companey_name }}</th>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.station_name }}</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">E Way Bill No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">E Way Bill Date</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">PO NO</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">PO Date</th>
                        </tr>
                        <tr class="">
                            <th class="py-2 border-b lg:text-m-r" colspan="2">{{ pagedata.purchase_item.eway_bill_no ? pagedata.purchase_item.eway_bill_no : '-' }}</th>
                            <th class="py-2 border-b lg:text-m-r" colspan="2">{{ pagedata.purchase_item.eway_bill_date ? pagedata.purchase_item.eway_bill_date : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.po_no ? pagedata.purchase_item.po_no : '-' }}</th>
                            <th class=" py-2 border-b lg:text-m-r" colspan="2">{{ pagedata.purchase_item.po_date ? pagedata.purchase_item.po_date : '-' }}</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Insurance No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Insurance Amount</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Freigth Type</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Freigth</th>
                        </tr>
                        <tr class="">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{ pagedata.purchase_item.insurance_no ? pagedata.purchase_item.insurance_no : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{ pagedata.purchase_item.insurance ? pagedata.purchase_item.insurance + ' INR' : '-' }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                                <span v-if="pagedata.purchase_item.freigth_type == 2">Ex-W</span>
                                <span v-if="pagedata.purchase_item.freigth_type == 3">For</span>
                                <span v-if="pagedata.purchase_item.freigth_type == 4">Godown Delivery</span>
                                <span v-if="pagedata.purchase_item.freigth_type == 1">Door Delivery</span>
                            </th>
                            <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r">{{ pagedata.purchase_item.freigth ? pagedata.purchase_item.freigth + ' INR' : '-' }}</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Warehouse Receipt Time</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Inward Date</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Inward Amount</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Accessable Value</th>
                        </tr>
                        <tr class="">
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.warehouse_receipt_time ? pagedata.purchase_item.warehouse_receipt_time : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{ pagedata.purchase_item.inward_date }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2" rowspan="5">{{ pagedata.purchase_item.inward_value }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2" rowspan="5">{{ pagedata.purchase_item.accessable_value }}</th>
                        </tr>
                    </table>

                    <h2 class="font-medium text-base mr-auto mt-5">Purchase Products</h2>
                    <table class="w-full border mt-3">
                        <thead>
                            <tr class="bg-gray-200 dark:bg-dark-1 border">
                                <th>Product Name</th>
                                <th>Material Specification</th>
                                <th>Product Type</th>
                                <th>Quantity</th>
                                <th>Per Unit Rate</th>
                                <th>Total Amount</th>
                                <th>GST</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in pagedata.purchase_item.purchase_details" class="text-center border" :key="index">
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.product_name }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.description }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.product_type == 3 ? 'Both' : (item.product_type == 1 ? 'Poly' : 'MBB') }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.quantity }} {{ item.uqc_name }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.value_per_unit }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.accessable_value }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.gst_value ? item.gst_value : '-' }} {{ item.gst && item.gst_value ? ' ( '+ item.gst + '% ) ' : '' }}</td>
                            </tr>
                        </tbody>

                    </table>

                    <h2 class="font-medium text-base mr-auto mt-5">Purchase Duties</h2>
                    <table class="w-full border mt-3" v-for="(item, index) in pagedata.purchase_item.purchase_details" :key="index">
                        <tr class="bg-gray-200 dark:bg-dark-1">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="7">{{ index + 1 }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Product Name</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Material Specification</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.product_name }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.description }}</th>
                        </tr>
                        <tr class="bg-gray-100 dark:bg-dark-1">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Quantity</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">UQC</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Product Amount</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Insurance</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Freigth</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">CIF Amount</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.quantity }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.uqc_name }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.accessable_value ? parseFloat(item.accessable_value).toFixed(2) : '-' }}</th>
                            <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.conversation_value && item.value_per_unit) ? (item.value_per_unit * item.conversation_value) : '' }}</th> -->
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.insurance ? parseFloat(item.insurance).toFixed(2) : '-' }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.freigth ? parseFloat(item.freigth).toFixed(2) : '-' }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.total_access_weight ? parseFloat(item.total_access_weight).toFixed(2) : '-' }}</th>
                        </tr>
                        <tr class="bg-gray-100 dark:bg-dark-1">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r"></th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">GST</th>
                            <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Comp Cess</th> -->
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Duty Total</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Total Amount With Duty</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">%</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.gst }}</th>
                            <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.comp_cess }}</th> -->
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="2" colspan="2">{{ item.duty_amount }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2" rowspan="2">{{ item.total_value }}</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Amount</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.gst_value }}</th>
                            <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.comp_cess_value }}</th> -->
                        </tr>
                    </table>

                </div>
            </div>
        </div>
    </div>
    <!--- END: Purchase Details Model --->
    <!--- BEGIN: Purchase Details Model --->
    <div id="import_purchase-details-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Purchase No : {{ pagedata.purchase_item.import_code }}</h2>
                        <div>
                            <a data-dismiss="modal" href="javascript:;">
                                <XIcon class="w-8 h-8 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body p-10">

                    <table class="w-full border mt-3">

                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Bill No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Bill Date</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Company Name</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Import Station</th>
                        </tr>
                        <tr class="">
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.bill_no ? pagedata.purchase_item.bill_no : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.bill_date ? pagedata.purchase_item.bill_date : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.companey_name ? pagedata.purchase_item.companey_name : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.station_name ? pagedata.purchase_item.station_name : '-' }}</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Invoice No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Invoice Date</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">PO No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">PO Date</th>
                        </tr>
                        <tr class="">
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.invoice_no ? pagedata.purchase_item.invoice_no : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.invoice_date ? pagedata.purchase_item.invoice_date : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.po_no ? pagedata.purchase_item.po_no : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{ pagedata.purchase_item.po_date ? pagedata.purchase_item.po_date : '-' }}</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Freigth Type</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Freigth</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Freigth Conversation</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Freigth Amount</th>
                        </tr>
                        <tr class="">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                                <span v-if="pagedata.purchase_item.freigth_type == 2">Ex-W</span>
                                <span v-if="pagedata.purchase_item.freigth_type == 3">For</span>
                                <span v-if="pagedata.purchase_item.freigth_type == 4">Godown Delivery</span>
                                <span v-if="pagedata.purchase_item.freigth_type == 1">Door Delivery</span>
                            </th>
                            <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r">{{ pagedata.purchase_item.freigth }} {{ pagedata.purchase_item.freigth_currency_name }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{ pagedata.purchase_item.freigth_con_value ? pagedata.purchase_item.freigth_con_value : '-' }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{ pagedata.purchase_item.freigth_value ? pagedata.purchase_item.freigth_value : '-' }}</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Bond Details</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Transport No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Insurance No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Insurance Amount</th>
                        </tr>
                        <tr class="">
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.bond_detail ? pagedata.purchase_item.bond_detail : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.transport_no ? pagedata.purchase_item.transport_no : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.insurance_no ? pagedata.purchase_item.insurance_no : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.insurance ? pagedata.purchase_item.insurance : '-' }} INR</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">One Time Lock</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Warehouse Receipt Time</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Inward Amount</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Accessible Value</th>
                        </tr>
                        <tr>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.one_time_lock ? pagedata.purchase_item.one_time_lock : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.warehouse_receipt_time ? pagedata.purchase_item.warehouse_receipt_time : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2" rowspan="2">{{ pagedata.purchase_item.inward_value }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2" rowspan="2">{{ pagedata.purchase_item.accessable_value }}</th>
                        </tr>
                        <tr>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Inward Date</th>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.inward_date }}</th>
                        </tr>
                    </table>

                    <h2 class="font-medium text-base mr-auto mt-5">Purchase Products</h2>
                    <table class="w-full border mt-3">
                        <thead>
                            <tr class="bg-gray-200 dark:bg-dark-1 border">
                                <th>Product Name</th>
                                <th>Material Specification</th>
                                <th>Product Type</th>
                                <th>Note</th>
                                <th>Quantity</th>
                                <th>Per Unit Rate</th>
                                <th>Conversation Value</th>
                                <th>Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in pagedata.purchase_item.purchase_details" class="text-center" :key="index">
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.product_name }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.description }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.product_type == 3 ? 'Both' : (item.product_type == 1 ? 'Poly' : 'MBB') }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.note ? item.note : '-') }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.quantity }} {{ item.uqc_name }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.value_per_unit ? $h.myFloat(item.value_per_unit) : 0) }} {{ item.value_currency_name }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.value_con ? item.value_con : '-') }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.accessable_value ? $h.myFloat(item.accessable_value) : 0) }}</td>
                            </tr>
                        </tbody>

                    </table>

                    <h2 class="font-medium text-base mr-auto mt-5">Purchase Duties</h2>
                    <table class="w-full border mt-3" v-for="(item, index) in pagedata.purchase_item.purchase_details" :key="index">
                        <tr class="bg-gray-200 dark:bg-dark-1">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="5">{{ index + 1 }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Product Name</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Material Specification</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.product_name }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.description }}</th>
                        </tr>
                        <tr class="bg-gray-100 dark:bg-dark-1">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Quantity</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">UQC</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Product Amount</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Insurance</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Freigth</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">CIF Amount</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.quantity }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.uqc_name }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.accessable_value ? parseFloat(item.accessable_value).toFixed(2) : '-' }}</th>
                            <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.conversation_value && item.value_per_unit) ? (item.value_per_unit * item.conversation_value) : '' }}</th> -->
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.insurance ? parseFloat(item.insurance).toFixed(2) : '-' }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.freigth ? parseFloat(item.freigth).toFixed(2) : '-' }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.total_access_weight ? parseFloat(item.total_access_weight).toFixed(2) : '-' }}</th>
                        </tr>
                        <tr class="bg-gray-100 dark:bg-dark-1">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">BCD</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Comp Cess</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">KFB</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">IGST</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Duty Total</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Total Amount With Duty</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">%</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.bcd ? item.bcd : '-') }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.comp_cess ? item.comp_cess : '-') }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.kfb ? item.kfb : '-') }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.igst ? item.igst : '-') }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="2">{{ (item.duty_amount ? item.duty_amount : '-') }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="2">{{ (item.total_value ? item.total_value : '-') }}</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Amount</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.bcd_value ? item.bcd_value : '-') }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.comp_cess_value ? item.comp_cess_value : '-') }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.kfb_value ? item.kfb_value : '-') }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.igst_value ? item.igst_value : '-') }}</th>
                        </tr>
                    </table>

                </div>
            </div>
        </div>
    </div>
    <!--- END: Purchase Details Model --->

    <!-- BEGIN: Modal Content -->
    <div id="ooc-number-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- BEGIN: Modal Header -->
                <div class="modal-header">
                    <h2 class="font-medium text-base mr-auto">
                        Confirm OOC Number
                    </h2>
                </div>
                <!-- END: Modal Header -->
                <!-- BEGIN: Modal Body -->
                <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                    <div class="col-span-12">
                        <div class="text-3xl mt-5 text-center">Are you sure?</div>
                        <div class="text-gray-600 mt-2 text-center">
                            Please note that this will automatically <br>
                            update OOC Number, <br>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Duty Code</label>
                        <input id="ref_no" type="text" v-model="pagedata.duty_code" readonly class="form-control" autocomplete="off" placeholder="Reference NO" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Material</label>
                        <input id="ref_no" type="text" v-model="pagedata.product_name" readonly class="form-control" autocomplete="off" placeholder="Reference NO" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Specification</label>
                        <input id="ref_no" type="text" v-model="pagedata.material_name" readonly class="form-control" autocomplete="off" placeholder="Reference NO" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Quantity</label>
                        <input id="ref_no" type="text" v-model="pagedata.quantity" readonly class="form-control" autocomplete="off" placeholder="Reference NO" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">OOC Number</label>
                        <input id="ooc_no" type="text" v-model="pagedata.ooc_no" class="form-control" placeholder="OOC NO" autocomplete="off" />
                    </div>
                    <div class="col-span-12">
                        <div class="text-black mt-2"><b>Please confirm the OOC No !</b></div>
                    </div>
                </div>
                <!-- END: Modal Body -->
                <!-- BEGIN: Modal Footer -->
                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-28 mr-1">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary w-28" :disabled="pagedata.ooc_loading" @click="accept_direct_duty_ooc_request">
                        Add OOC
                        <LoadingIcon v-if="pagedata.ooc_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                </div>
                <!-- END: Modal Footer -->
            </div>
        </div>
    </div>
    <!-- END: Modal Content -->
    <!-- BEGIN: Modal Content -->
    <div id="reference-number-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- BEGIN: Modal Header -->
                <div class="modal-header">
                    <h2 class="font-medium text-base mr-auto">
                        Confirm Reference Number
                    </h2>
                </div>
                <!-- END: Modal Header -->
                <!-- BEGIN: Modal Body -->
                <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                    <div class="col-span-12">
                        <div class="text-3xl mt-5 text-center">Are you sure?</div>
                        <div class="text-gray-600 mt-2 text-center">
                            Please note that this will automatically <br>
                            update Reference Number, <br>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Duty Code</label>
                        <input id="ref_no" type="text" v-model="pagedata.duty_code" readonly class="form-control" autocomplete="off" placeholder="Reference NO" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Material</label>
                        <input id="ref_no" type="text" v-model="pagedata.product_name" readonly class="form-control" autocomplete="off" placeholder="Reference NO" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Specification</label>
                        <input id="ref_no" type="text" v-model="pagedata.material_name" readonly class="form-control" autocomplete="off" placeholder="Reference NO" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Quantity</label>
                        <input id="ref_no" type="text" v-model="pagedata.quantity" readonly class="form-control" autocomplete="off" placeholder="Reference NO" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Duty Amount</label>
                        <input id="ref_no" type="text" v-model="pagedata.duty_amount" readonly class="form-control" autocomplete="off" placeholder="Duty Amount" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Confirm Duty</label>
                        <input id="ref_no" type="text" v-model="pagedata.duty_amount_conf" class="form-control" autocomplete="off" placeholder="Confirm Duty" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Settlement Amount</label>
                        <input id="ref_no" type="text" v-model="pagedata.settlement_amount" class="form-control" autocomplete="off" placeholder="Settlement Amount" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Reference Number</label>
                        <input id="ref_no" type="text" v-model="pagedata.ref_no" class="form-control" autocomplete="off" placeholder="Reference NO" />
                    </div>
                    <div class="col-span-12">
                        <div class="text-black mt-2"><b>Please confirm the Reference No !</b></div>
                    </div>
                </div>
                <!-- END: Modal Body -->
                <!-- BEGIN: Modal Footer -->
                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-28 mr-1">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary w-28" :disabled="pagedata.ref_loading" @click="accept_direct_duty_request">
                        Duty Pay
                        <LoadingIcon v-if="pagedata.ref_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                </div>
                <!-- END: Modal Footer -->
            </div>
        </div>
    </div>
    <!-- END: Modal Content -->
</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    onMounted,
    inject
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import axios from 'axios'
import VueRouter from 'vue-router'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constant/config.js'
import cash from 'cash-dom'
import {
    helper as $h
} from '@/utils/helper'

export default defineComponent({
    setup() {
        const swal = inject('$swal')
        const tableRef = ref()
        const tabulator = ref()
        const pagedata = reactive({
            product_details: [],
            purchase_item: [],
            material_details: [],
            accept_loading: false,
            ooc_loading: false,
            ref_loading: false,
            duty_amount: "",
            duty_amount_conf: "",
            settlement_amount: "",
            ooc_no: "",
            ref_no: "",
            duty_id: "",
            ref_id: "",
            product_name: "",
            material_name: "",
            quantity: "",
            request_code: "",
            duty_code: "",
        })
        const duty_filters = reactive({
            product_id: "0",
            material_id: "0",
            product_type: "0",
            status: "0",
            duty_code: "",
        })
        const print_filters = reactive({
            product_id: "0",
            material_id: "0",
            product_type: "0",
            status: "0",
            duty_code: "",
        })

        const initTabulator = () => {

            print_filters.product_id = duty_filters.product_id
            print_filters.material_id = duty_filters.material_id
            print_filters.product_type = duty_filters.product_type
            print_filters.status = duty_filters.status
            print_filters.duty_code = duty_filters.duty_code

            let params = {
                "product_id": print_filters.product_id == "0" ? "" : window.btoa(print_filters.product_id),
                "material_id": print_filters.material_id == "0" ? "" : window.btoa(print_filters.material_id),
                "product_type": print_filters.product_type == "0" ? "" : window.btoa(print_filters.product_type),
                "status": print_filters.status == "0" ? "" : window.btoa(print_filters.status),
                "duty_code": print_filters.duty_code == "" ? "" : window.btoa(print_filters.duty_code)
            }
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + 'api/get_direct_duty_pay_request',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: params,
                pagination: 'remote',
                paginationSize: 20,
                // renderHorizontal:"virtual",
                paginationSizeSelector: [10, 20, 30, 40],
                ajaxFiltering: true,
                ajaxSorting: true,
                headerSort: false,
                printAsHtml: true,
                printStyled: true,
                layout: 'fitColumns',
                responsiveLayout: 'collapse',
                placeholder: 'No matching records found',
                columns: [{
                        formatter: 'responsiveCollapse',
                        width: 40,
                        minWidth: 30,
                        hozAlign: 'center',
                        resizable: false,
                        headerSort: false
                    },

                    // For HTML table
                    {
                        title: 'Duty Code',
                        minWidth: 100,
                        responsive: 0,
                        field: 'duty_code',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter: "textarea",
                        cellClick: function(e, cell) {
                          getProductDetails(cell.getData().main_purchase_id, cell.getData().purchase_type)
                        },
                        formatter(cell) {
                            return `<button class="btn btn-outline-primary btn-sm hover:text-white">` + cell.getData().duty_code +`</button>`
                        },
                    },
                    {
                        title: 'Bill No',
                        width: 115,
                        responsive: 0,
                        field: 'bill_no',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter: "textarea"
                    },
                    {
                        title: 'Bill Date',
                        width: 115,
                        responsive: 0,
                        field: 'bill_date',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter: "textarea"
                    },
                    {
                        title: 'Import No',
                        width: 115,
                        responsive: 0,
                        field: 'import_code',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter: "textarea"
                    },
                    {
                        title: 'Material',
                        minWidth: 100,
                        responsive: 0,
                        field: 'product_name',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter: "textarea",
                        cellClick: function(e, cell) {
                          getProductDetails(cell.getData().main_purchase_id, cell.getData().purchase_type)
                        }
                    },
                    {
                        title: 'Description',
                        minWidth: 150,
                        responsive: 0,
                        field: 'description',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter: "textarea",
                        cellClick: function(e, cell) {
                          getProductDetails(cell.getData().main_purchase_id, cell.getData().purchase_type)
                        }
                    },
                    {
                        title: 'Type',
                        minWidth: 100,
                        responsive: 0,
                        field: 'product_type',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            return cell.getData().product_type == 1 ? "Poly" : (cell.getData().product_type == 2 ? "MBB" : "Both")
                        },
                        cellClick: function(e, cell) {
                          getProductDetails(cell.getData().main_purchase_id, cell.getData().purchase_type)
                        }
                    },
                    {
                        title: 'Note',
                        width: 115,
                        responsive: 0,
                        field: 'note',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter: "textarea",
                    },
                    {
                        title: 'Quantity',
                        minWidth: 100,
                        responsive: 0,
                        field: 'quantity',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            return $h.myFloat(cell.getData().quantity) + cell.getData().uqc_name
                        },
                        cellClick: function(e, cell) {
                          getProductDetails(cell.getData().main_purchase_id, cell.getData().purchase_type)
                        }
                    },
                    {
                        title: 'Amount',
                        minWidth: 100,
                        responsive: 0,
                        field: 'duty_amount',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            return cell.getData().duty_amount ? $h.myFloat(cell.getData().duty_amount) : "-"
                        },
                        cellClick: function(e, cell) {
                          getProductDetails(cell.getData().main_purchase_id, cell.getData().purchase_type)
                        }
                    },
                    {
                        title: 'Ref No',
                        minWidth: 100,
                        responsive: 0,
                        field: 'ref_no',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            return cell.getData().ref_no ? cell.getData().ref_no : "-"
                        },
                        cellClick: function(e, cell) {
                          getProductDetails(cell.getData().main_purchase_id, cell.getData().purchase_type)
                        }
                    },
                    {
                        title: 'OOC No',
                        minWidth: 100,
                        responsive: 0,
                        field: 'ooc_no',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            return cell.getData().ooc_no ? cell.getData().ooc_no : "-"
                        },
                        cellClick: function(e, cell) {
                          getProductDetails(cell.getData().main_purchase_id, cell.getData().purchase_type)
                        }
                    },
                    {
                        title: 'Status',
                        minWidth: 100,
                        field: 'active_status',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            if (cell.getData().active_status == 1) {

                                return '<div class="flex items-center lg:justify-center text-theme-1">Pending</div>'

                            } else if (cell.getData().active_status == 2) {

                                return '<div class="flex items-center lg:justify-center text-theme-3">OOC Waiting</div>'

                            } else if (cell.getData().active_status == 3) {

                                return '<div class="flex items-center lg:justify-center text-theme-6">Rejected</div>'

                            } else if (cell.getData().active_status == 4) {

                                return '<div class="flex items-center lg:justify-center text-theme-9">Duty Paid</div>'
                            }
                        },
                        cellClick: function(e, cell) {
                          getProductDetails(cell.getData().main_purchase_id, cell.getData().purchase_type)
                        }
                    },
                    {
                        title: 'Actions',
                        minWidth: 100,
                        field: 'actions',
                        responsive: 1,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            if (cell.getData().active_status == 1) {

                                const a = cash(`<div class="flex lg:justify-center items-center">
                  <button class="btn btn-primary btn-sm py-1">
                    <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Duty Pay
                  </button>

                </div>`)
                                cash(a).on('click', function () {
                                    pagedata.ref_id = cell.getData().id
                                    pagedata.ref_no = ''
                                     pagedata.product_name = cell.getData().product_name
                                     pagedata.duty_amount = cell.getData().duty_amount
                                    pagedata.material_name = cell.getData().description
                                    pagedata.quantity = $h.myFloat(cell.getData().quantity) + cell.getData().uqc_name
                                    pagedata.duty_code = cell.getData().duty_code
                                    cash('#reference-number-modal').modal('show')

                                    setTimeout(() => {
                                        document.querySelector('[id="ref_no"]').focus();
                                    }, 50);
                                })

                                return a[0]
                            } else if (cell.getData().active_status == 2) {

                                const a = cash(`<div class="flex lg:justify-center items-center">
                  <button class="btn btn-primary btn-sm py-1">
                    <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Add OOC
                  </button>

                </div>`)
                                cash(a).on('click', function () {
                                    pagedata.duty_id = cell.getData().id
                                    pagedata.ooc_no = ''
                                     pagedata.product_name = cell.getData().product_name
                                    pagedata.material_name = cell.getData().description
                                    pagedata.duty_amount = cell.getData().duty_amount
                                    pagedata.quantity = $h.myFloat(cell.getData().quantity) + cell.getData().uqc_name
                                    pagedata.duty_code = cell.getData().duty_code
                                    cash('#ooc-number-modal').modal('show')

                                    setTimeout(() => {
                                        document.querySelector('[id="ooc_no"]').focus();
                                    }, 50);
                                })

                                return a[0]
                            } else if (cell.getData().active_status == 3) {

                                const a = cash(`<div class="flex lg:justify-center items-center">
                  <button class="btn btn-primary btn-sm py-1" disabled>
                    <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Duty Pay
                  </button>

                </div>`)

                                return a[0]
                            } else if (cell.getData().active_status == 4) {

                                const a = cash(`<div class="flex lg:justify-center items-center">
                  <button class="btn btn-primary btn-sm py-1" disabled>
                    <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Duty Pay
                  </button>

                </div>`)
                                return a[0]
                            }
                        }
                    },
                    {
                        title: 'Reject',
                        minWidth: 100,
                        field: 'actions',
                        responsive: 1,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            if (cell.getData().active_status == 1) {

                                const a = cash(`<div class="flex lg:justify-center items-center">
                  <button class="btn btn-danger btn-sm py-1">
                    <i data-feather="trash" class="w-4 h-4 mr-1"></i>
                  </button>

                </div>`)
                                cash(a).on('click', function () {
                                    cancel_direct_duty_request(cell.getData().id)
                                })

                                return a[0]
                            } else {

                                const a = cash(`<div class="flex lg:justify-center items-center">
                  <button class="btn btn-primary btn-sm py-1" disabled>
                    <i data-feather="trash" class="w-4 h-4 mr-1"></i>
                  </button>

                </div>`)
                                return a[0]
                            }
                        }
                    },

                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })

            sessionStorage.setItem('direct_duty_filters', JSON.stringify(print_filters))
        }

        const getProductDetails = async (id, type) => {
            // console.log(id);
            const params = {
                "purchase_id": window.btoa(id)
            };

            cash('#import_purchase-details-modal').modal('show')

            let api_url = ""
            if (type == 1) {

                api_url = "api/purchase/get_import_purchase_single_item"
            } else {

                api_url = "api/purchase/get_dta_purchase_single_item"
            }
            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    pagedata.purchase_item = data.items;

                    if (type == 1) {

                        // cash('#import_purchase-details-modal').modal('show')
                    } else {

                        // cash('#dta_purchase-details-modal').modal('show')
                    }
                })
        }

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // Get Material Details
        const productDetails = async () => {

            const params = {};

            let promise = axios({
                url: "api/master/dp_product",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.product_details = data.items

                })
                .catch(_error => {

                    pagedata.product_details = []

                });
        }

        // Get Material Details
        const materialDetails = async () => {

            const params = {
                product_id: duty_filters.product_id != "0" ? window.btoa(duty_filters.product_id) : ""
            };

            let promise = axios({
                url: "api/master/get_details_for_tax",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.material_details = data.items

                    let is_match = false
                    pagedata.material_details.forEach(element => {
                        if (element.id == duty_filters.material_id) {
                            is_match = true
                        }
                    });

                    if (is_match == false) {
                        duty_filters.material_id = "0"
                    }
                })
                .catch(_error => {

                    duty_filters.material_id = "0"
                    pagedata.material_details = []

                });
        }

        // Accept Duty Paid
        const accept_direct_duty_request = async () => {

          console.log(pagedata.duty_amount)
          console.log($h.myFloat(parseFloat(pagedata.duty_amount_conf ? pagedata.duty_amount_conf : 0) + parseFloat(pagedata.settlement_amount ? pagedata.settlement_amount : 0)))

          if($h.myFloat(pagedata.duty_amount ? pagedata.duty_amount : 0) != $h.myFloat(parseFloat(pagedata.duty_amount_conf ? pagedata.duty_amount_conf : 0) + parseFloat(pagedata.settlement_amount ? pagedata.settlement_amount : 0)) ) {
            swal({
                title: "Warning!",
                icon: "warning",
                text: "Invalid Duty Amount.",
                type: "warning",
                confirmButtonColor: "#064e3b",
            })
            return;
          }


            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Confirm Duty Pay!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Duty Pay",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    if (pagedata.ref_no == "") {
                        swal({
                            title: "Warning!",
                            icon: "warning",
                            text: "Please Enter Reference Number First.",
                            type: "warning",
                            confirmButtonColor: "#064e3b",
                        })
                        // swal("Warning!", "Please Enter OOC Number First.", "warning");
                    } else {

                        pagedata.ref_loading = true

                        const params = {
                            id: window.btoa(pagedata.ref_id),
                            ref_no: pagedata.ref_no == "" ? "" : window.btoa(pagedata.ref_no)
                        };

                        let promise = axios({
                            url: "api/accept_direct_duty_request",
                            method: "POST",
                            data: params,
                            baseURL: BASE_URL,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token")
                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            }
                        });
                        return promise
                            .then(result => result.data)
                            .then(data => {

                                if (data.error == "NO") {
                                    swal.fire({
                                        icon: "success",
                                        title: "Success!",
                                        text: data.message,
                                        showConfirmButton: false,
                                        timer: 2000
                                    })
                                    initTabulator()
                                    resetForm()
                                    pagedata.ref_loading = false
                                    cash('#reference-number-modal').modal('hide')
                                } else {
                                    swal({
                                        title: "Warning!",
                                        icon: "warning",
                                        text: data.message,
                                        type: "warning",
                                        confirmButtonColor: "#064e3b",
                                    })
                                    pagedata.ref_loading = false
                                }

                            })
                            .catch(_error => {
                                pagedata.ref_loading = false

                            });
                    }
                }
            });
        }

        // Accept Duty Paid
        const cancel_direct_duty_request = async (id) => {

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Cancel Duty Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Reject",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    const params = {
                        id: window.btoa(id)
                    };

                    let promise = axios({
                        url: "api/cancel_duty_request",
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {
                                swal.fire({
                                    icon: "success",
                                    title: "Success!",
                                    text: data.message,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                initTabulator()
                            } else {
                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })

                            }

                        })
                        .catch(_error => {

                        });

                }
            });
        }

        // Accept OOC Paid
        const accept_direct_duty_ooc_request = async () => {

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Enter OOC No to the Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Add OOC",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    if (pagedata.ooc_no == "") {
                        swal({
                            title: "Warning!",
                            icon: "warning",
                            text: "Please Enter OOC Number First.",
                            type: "warning",
                            confirmButtonColor: "#064e3b",
                        })
                        // swal("Warning!", "Please Enter OOC Number First.", "warning");
                    } else {

                        pagedata.ooc_loading = true

                        const params = {
                            id: window.btoa(pagedata.duty_id),
                            ooc_no: pagedata.ooc_no == "" ? "" : window.btoa(pagedata.ooc_no)
                        };

                        let promise = axios({
                            url: "api/accept_direct_duty_ooc_request",
                            method: "POST",
                            data: params,
                            baseURL: BASE_URL,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token")
                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            }
                        });
                        return promise
                            .then(result => result.data)
                            .then(data => {

                                if (data.error == "NO") {
                                    swal.fire({
                                        icon: "success",
                                        title: "Success!",
                                        text: data.message,
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                    initTabulator()
                                    resetForm()
                                    pagedata.ooc_loading = false
                                    cash('#ooc-number-modal').modal('hide')
                                } else {
                                    swal({
                                        title: "Warning!",
                                        icon: "warning",
                                        text: data.message,
                                        type: "warning",
                                        confirmButtonColor: "#064e3b",
                                    })
                                    pagedata.ooc_loading = false
                                }

                            })
                            .catch(_error => {
                                pagedata.ooc_loading = false

                            });
                    }
                }
            });
        }

        // On reset filter
        const resetStockFilters = () => {
            duty_filters.product_id = "0";
            duty_filters.material_id = "0";
            duty_filters.product_type = "0";
            duty_filters.status = "0";
            duty_filters.duty_code = "";
        }

        const resetForm = () => {
          pagedata.ref_id = ''
          pagedata.ref_no = ''
          pagedata.ooc_no = ''
          pagedata.product_name = ''
          pagedata.material_name = ''
          pagedata.duty_amount = ''
          pagedata.duty_amount_conf = ''
          pagedata.settlement_amount = ''
          pagedata.quantity = ''
          pagedata.request_code = ''
          pagedata.duty_code = ''
        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'data.xlsx', {
                sheetName: 'Products'
            })
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        onMounted(() => {

            let filter_items = sessionStorage.getItem('direct_duty_filters') != null ? JSON.parse(sessionStorage.getItem('direct_duty_filters')) : null

            if (filter_items != null) {
                duty_filters.product_id = filter_items.product_id
                duty_filters.material_id = filter_items.material_id
                duty_filters.product_type = filter_items.product_type
                duty_filters.status = filter_items.status
                duty_filters.duty_code = filter_items.duty_code

            }
            productDetails()
            materialDetails()
            initTabulator()
            reInitOnResizeWindow()
        })

        return {
            tableRef,
            pagedata,
            duty_filters,
            print_filters,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            initTabulator,
            materialDetails,
            resetStockFilters,
            accept_direct_duty_request,
            accept_direct_duty_ooc_request,
            cancel_direct_duty_request
        }
    }
})
</script>
