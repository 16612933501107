<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 pb-3">

        <h2 class="text-lg font-medium mr-auto">Request Form</h2>

        <div class="intro-y box p-3">
          <h2 class="text-lg font-medium mr-auto py-3">Request Form</h2>
          <table class="w-full border mt-3">
            <thead>
              <tr>
                <th>No</th>
                <th>Party</th>
                <th>Watt</th>
                <th>Pannel Type</th>
                <th>Quantity</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-b" v-for="(plan,index) in form_data" :key="index">
                <td>{{ index + 1 }}</td>
                <td :id="'party_id_' + index" class="p-0 m-0">
                  <TomSelect
                    v-model="plan.party_id"
                      :options="{
                        placeholder: 'Select Material'
                      }"
                      class="w-full"
                      name="Material"
                      id="pos-form-2"
                      @change="get_product_consumption_details(plan)"
                  >
                  <option value="0">Select Party</option>
                  <option v-for="item in pagedata.party_items" :key="item.id" :value="item.id">{{ item.companey_name}}</option>
                  </TomSelect>
                </td>
                <td :id="'watt_id_' + index" class="p-0 m-0">
                  <TomSelect
                    v-model="plan.watt_id"
                      :options="{
                        placeholder: 'Select Watt'
                      }"
                      class="w-full"
                      name="Watt"
                      id="pos-form-2"
                      @change="get_product_consumption_details(plan)"
                  >
                  <option value="0">Select Watt</option>
                  <option v-for="item in pagedata.watt_items" :key="item.id" :value="item.id">{{ item.watt}}</option>
                  </TomSelect>
                </td>
                <td :id="'pannel_type_' + index" class="p-0 m-0">
                  <TomSelect
                    v-model="plan.pannel_type"
                      :options="{
                        placeholder: 'Select Module Type'
                      }"
                      class="w-full"
                      name="Module Type"
                      id="pos-form-2"
                      @change="get_product_consumption_details(plan)"
                  >
                  <option value="0">Select Type</option>
                  <option value="1">Poly</option>
                  <option value="2">MBB</option>
                  </TomSelect>
                </td>
                <td class="w-60">
                  <input v-model="plan.quantity" type="number" :id="'quantity_' + index"
                  @change="calculate_quantity_items(plan)"
                  class="form-control w-full" placeholder="Quantity">
                </td>
                <td>
                  <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1"  @click="remove_request_field(plan)">
                    <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1"/>
                  </button>
                </td>
              </tr>
            </tbody>

          </table>
          <div class="flex items-center justify-between">
            <div>
              <button type="button" @click="add_new_request_field" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">Add Product</button>
            </div>
            <div>

              <button
                type="button"
                @click="$router.go(-1)"
                class="btn btn-outline-secondary w-32 mr-1"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-primary w-32" :disabled="pagedata.submit" @click="send_request_to_stock_department">
                Save Details <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>

      </div>

      <div class="intro-y col-span-12 pb-3">
        <div class="intro-y box p-3">
          <h2 class="font-medium text-lg mr-auto">Estimate Consumptionds</h2>
          <div class="my-6 shadow-xl" v-for="(main_item, index) in form_data" :key="index">

            <table class="table w-full text-center" v-if="main_item.product_details.length > 0">
              <thead>
                <tr class="bg-theme-14">
                  <th class="border-b dark:border-dark-5 whitespace-nowrap">{{ index + 1 }}</th>
                  <th class="border-b dark:border-dark-5 whitespace-nowrap"><b>Outward Party : </b>{{ main_item.party_name }}</th>
                  <th class="border-b dark:border-dark-5 whitespace-nowrap"><b>Type : </b>{{ main_item.pannel_type == 1 ? 'Poly':(main_item.pannel_type == 2 ? 'MBB' : '') }}</th>
                  <th class="border-b dark:border-dark-5 whitespace-nowrap"><b>Watt : </b>{{ main_item.watt }}</th>
                  <th class="border-b dark:border-dark-5 whitespace-nowrap"><b>Quantity : </b>{{ main_item.quantity }}</th>
                  <!-- <th class="border-b dark:border-dark-5 whitespace-nowrap"></th> -->
                </tr>
                <tr class="bg-theme-14">
                  <th class="border-b dark:border-dark-5 whitespace-nowrap"></th>
                  <th class="border-b dark:border-dark-5 whitespace-nowrap">Material</th>
                  <!-- <th class="border-b dark:border-dark-5 whitespace-nowrap">Specification</th> -->
                  <th class="border-b dark:border-dark-5 whitespace-nowrap">Type</th>
                  <th class="border-b dark:border-dark-5 whitespace-nowrap">Quantity <br> Per Module</th>
                  <th class="border-b dark:border-dark-5 whitespace-nowrap">Quantity</th>
                </tr>
              </thead>
              <tbody class="text-center" v-if="main_item.product_details.length > 0">
                <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (inx % 2) == 0}" v-for="(item, inx) in main_item.product_details" :key="inx">
                  <td class="border-b">{{ inx + 1 }}</td>
                  <td class="border-b">{{ item.product_name }}</td>
                  <!-- <td class="border-b">{{ item.description }}</td> -->
                  <td class="border-b">{{ item.product_type == 1 ? 'Poly' : (item.product_type == 2 ? 'MBB' : '') }}</td>
                  <td class="border-b">{{ item.quantity }} {{ item.uqc_name }}</td>
                  <td class="border-b">{{ item.request_quantity }} {{ item.request_quantity ? item.uqc_name : '-' }}</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constant/config'
import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { defineComponent, inject, onMounted, reactive, ref, watch } from 'vue'
import { stringify } from 'json5'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'

export default defineComponent({
  setup() {
    const swal = inject('$swal')
    const route = useRoute()
    const pagedata = reactive({
      watt_items: [],
      party_items: [],
      submit: false,
      count_ids: 1
    })
    const form_data = ref([{
      id: 1,
      update_id: '',
      party_id: '0',
      party_name: '',
      watt_id: '0',
      watt: '',
      pannel_type : '0',
      quantity : '',
      new_quantity : '',
      product_details: []
    }])

    // Get Size Details
    const get_size_details = async () => {

      let promise_url = "api/master/dp_size";
      let params = {

      }

      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {
        pagedata.watt_items = data.items
      })
      .catch(_error => {
        pagedata.watt_items = []

      });
    }

    // Get Outward Party Details
    const get_outparty_details = async () => {

      let promise_url = "api/master/dp_outward_party";
      let params = {

      }

      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {
        pagedata.party_items = data.items
      })
      .catch(_error => {
        pagedata.party_items = []

      });
    }

    // Add Request To New Department
    const send_request_to_stock_department = async () => {

      if(form_data.value.length == 0) {
        swal({
          title: "Warning!",
          icon: "warning",
          text: "Fields are Empty!",
          type: "warning",
          confirmButtonColor: "#064e3b",
        })
        return
      }
      // Validate Fileds
      let purchase_detail_length = form_data.value.length


      let is_empty = false

      for(let i = 0;i < purchase_detail_length;i++) {


        if(form_data.value[i].party_id == '0') {
          $('#party_id_' + i).addClass('border border-theme-6');
          is_empty = true;
        } else {
          $('#party_id_' + i).removeClass('border border-theme-6');
        }
        if(form_data.value[i].watt_id == '0') {
          $('#watt_id_' + i).addClass('border border-theme-6');
          is_empty = true;
        } else {
          $('#watt_id_' + i).removeClass('border border-theme-6');
        }
        if(form_data.value[i].pannel_type == '0') {
          $('#pannel_type_' + i).addClass('border border-theme-6');
          is_empty = true;
        } else {
          $('#pannel_type_' + i).removeClass('border border-theme-6');
        }
        if(form_data.value[i].quantity == '' || form_data.value[i].quantity == null) {
          $('#quantity_' + i).addClass('border-theme-6');
          is_empty = true;
        } else {
          $('#quantity_' + i).removeClass('border-theme-6');
        }
      }

      if(is_empty == true) {
      swal({
          title: "Warning!",
          icon: "warning",
          text: "Fileds Are Empty!",
          type: "warning",
          confirmButtonColor: "#064e3b",
        })
      return
      }


      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You want to Send Request to Stock Department!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#064e3b",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){

            pagedata.submit = true

            const params = {
              "items": form_data.value
            };

            let promise = axios({
              url: "api/set_outward_request",
              method: "POST",
              data: params,
              baseURL: BASE_URL,
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
              config: { headers: { Accept: "application/json" } }
            });
            return promise
              .then(result => result.data)
              .then(data => {

                if(data.error == "NO") {
                  swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  })
                  pagedata.submit = false
                  router.push({name: 'stock-dept-create-outward-request-list'})

                } else {
                  swal({
                    title: "Warning!",
                    icon: "warning",
                    text: data.message,
                    type: "warning",
                    confirmButtonColor: "#064e3b",
                  })
                  pagedata.submit = false

                }
              })
              .catch(_error => {
                pagedata.submit = false

              });

          }
      });

    }

    // Add Product Field
    const add_new_request_field = async () => {

      pagedata.count_ids += 1;
      form_data.value.push({
        id: pagedata.count_ids,
        update_id: '',
        party_id: '0',
        party_name: '',
        watt_id: '0',
        watt: '',
        pannel_type : '0',
        quantity : '',
        new_quantity : '',
        product_details: []
      });

    }

    // Get Consumption Details
    const get_product_consumption_details = async (item) => {

      if(item.party_id != "0" && item.party_name == "")
      {
        pagedata.party_items.forEach(element => {
          if(element.id == item.party_id) {
            item.party_name = element.companey_name
          }
        });
      }


      // Check if Fields is Empty or Not
      if(item.watt_id == "0" && item.pannel_type == "0") {

      } else {

        if(item.watt_id != "0" && item.watt == "")
        {
          pagedata.watt_items.forEach(element => {
            if(element.id == item.watt_id) {
              item.watt = element.watt
            }
          });
        }

        // Get The Consumption Products

        let params = {
          watt_id: window.btoa(item.watt_id),
          pannel_type: window.btoa(item.pannel_type)
        }

        let promise_url = "api/master/get_single_consumption_details"

        let promise = axios({
          url: promise_url,
          method: "POST",
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
        .then(result => result.data)
        .then(data => {
          item.product_details = data.items

          if(item.quantity != '' && item.product_details.length > 0) {
            calculate_quantity_items(item)
          }
        })
        .catch(_error => {
          item.product_details = []

        });

      }
    }

    // Calculate Product Quantity
    const calculate_quantity_items = async (item) => {

      if(item.product_details.length > 0 && item.watt_id != "0" && item.pannel_type != "0" && item.quantity != '') {

        // Calculate Product Quantity

        if (isNaN(item.quantity)) {
          swal({
            title: "Warning!",
            icon: "warning",
            text: "Please Enter a valid Quentity",
            type: "warning",
            confirmButtonColor: "#064e3b",
          })
          item.quantity = ""
          return
        }

        item.product_details.forEach(element => {
          element.request_quantity = $h.myFloat(item.quantity * element.quantity)
        });
      }

    }

    // Remove Request From Pannel
    const remove_request_field = async (item) => {

      swal({
          title: "Are you sure?",
          text: "You are about to delete a item!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#064e3b",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
          showLoaderOnConfirm: true,
          cancelButtonText: "Cancel",
          allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if (result.isConfirmed) {
              var i = form_data.value.length;
              while (i--) {
                  if (form_data.value[i]["id"] == item.id) {
                    form_data.value.splice(i, 1);
                    form_data.value = form_data.value.filter(function(){return true;});
                    console.warn(form_data.value)
                    break
                  }
              }
          }
      });

    }

    onMounted(() => {
      get_size_details()
      get_outparty_details()

      if(route.params.id) {
        setTimeout(() => {
          get_details_for_update_request()
        }, 100);
      }
    })
    return {
      form_data,pagedata,
      send_request_to_stock_department,add_new_request_field,
      remove_request_field,calculate_quantity_items,get_product_consumption_details,

    }
  }
})
</script>
<style scoped>
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .input-group .input-group-text-custom {
            border-width: 1px;
            --tw-border-opacity: 1;
            border-color: rgba(226, 232, 240, var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            --tw-text-opacity: 1;
            color: rgba(113, 128, 150, var(--tw-text-opacity));
            --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    </style>

