<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Outward Module Report</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="grid grid-cols-12 gap-x-2 mt-5">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="input-quantity" class="form-label py-1">OW NO</label>
          <input
            id="input-quantity"
            class="form-control"
            placeholder="OW NO"
            v-model="outward_filters.request_code"
            type="text">
        </div>
        <!-- <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label py-1">Select Party</label>
          <TomSelect
            v-model="outward_filters.party_id"
              :options="{
                placeholder: 'Select Party'
              }"
              class="w-full"
              name="Party"
              id="pos-form-2"
          >
          <option value="0">Select Party</option>
          <option v-for="item in pagedata.party_details" :key="item.id" :value="item.id">{{ item.companey_name}}</option>
          </TomSelect>
        </div> -->
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label py-1">Select Watt</label>
          <TomSelect
            v-model="outward_filters.watt_id"
              :options="{
                placeholder: 'Select Watt'
              }"
              class="w-full"
              name="Watt"
              id="pos-form-2"
          >
          <option value="0">Select Watt</option>
          <option v-for="item in pagedata.watt_details" :key="item.id" :value="item.id">{{ item.watt}}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label py-1">Module Type</label>
          <TomSelect
            v-model="outward_filters.pannel_type"
              :options="{
                placeholder: 'Select Module Type'
              }"
              class="w-full"
              name="Module Type"
              id="pos-form-2"
          >
          <option value="0">Select Module Type</option>
          <option value="1">Poly</option>
          <option value="2">MBB</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">

          <label for="update-profile-form-6" class="form-label py-1"
            >Select Start Date</label
          >
          <input
            type= "date"
            id="update-profile-form-13"

            class="form-control"
            placeholder=""
            v-model="outward_filters.s_date"
          />
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">

          <label for="update-profile-form-6" class="form-label py-1"
            >Select End Date</label
          >
          <input
            type= "date"
            id="update-profile-form-13"

            class="form-control"
            placeholder=""
            v-model="outward_filters.e_date"
          />
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-7">
            <button
            @click="resetStockFilters"
            class="btn btn-secondary w-full mt-2">
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-7">
            <button
            @click="initTabulator()"
            class="btn btn-primary w-full mt-2">
              Generate
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!--- BEGIN: Consumption Products Model --->
    <div id="product-details-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <div class="flex items-center justify-between w-full">
              <h2 class="font-medium text-base mr-auto">Outward Materials</h2>
              <div>
                <a data-dismiss="modal" href="javascript:;">
                  <XIcon class="w-8 h-8 text-gray-500" />
                </a>
              </div>
            </div>
          </div>
          <div class="modal-body py-5 px-5">

            <div class="grid grid-cols-12 gap-x-2">
              <div class="col-span-12 md:col-span-6 xl:col-span-5 py-1">
                <h2 class="font-medium mt-2 mr-auto"><b>OW NO</b> : {{ pagedata.request_details.request_item.request_code }}</h2>
              </div>
              <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1">
                <h2 class="font-medium mt-2 mr-auto"><b>Watt</b> : {{ pagedata.request_details.request_item.watt }}</h2>
              </div>
              <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1">
                <h2 class="font-medium mt-2 mr-auto"><b>Party</b> : {{ pagedata.request_details.request_item.companey_name }}</h2>
              </div>
              <div class="col-span-12 md:col-span-6 xl:col-span-5 py-1">
                <h2 class="font-medium mt-2 mr-auto"><b>Request Date</b> : {{ pagedata.request_details.request_item.issue_date }}</h2>
              </div>
              <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1">
                <h2 class="font-medium mt-2 mr-auto"><b>Type</b> : {{ pagedata.request_details.request_item.product_type == 1 ? 'Poly' : 'MBB' }}</h2>
              </div>
              <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1">
                <h2 class="font-medium mt-2 mr-auto"><b>Request Module</b> : {{ pagedata.request_details.request_item.quantity }}</h2>
              </div>
              <div class="col-span-12 md:col-span-6 xl:col-span-5 py-1">
                <h2 class="font-medium mt-2 mr-auto"><b>Accepted Date</b> : {{ pagedata.request_details.request_item.accepted_at ? pagedata.request_details.request_item.accepted_at : '-' }}</h2>
              </div>
              <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1" v-if="pagedata.request_details.request_item.active_status">
                <h2 class="font-medium mt-2 mr-auto"><b>Status</b> :
                  <span class="text-theme-1" v-if="pagedata.request_details.request_item.active_status == 1">Pending</span>
                  <span class="text-theme-3" v-if="pagedata.request_details.request_item.active_status == 2">Duty Pending</span>
                  <span class="text-theme-6" v-if="pagedata.request_details.request_item.active_status == 3">Rejected</span>
                  <span class="text-theme-9" v-if="pagedata.request_details.request_item.active_status == 4">Accepted</span>
                  <span class="text-theme-9" v-if="pagedata.request_details.request_item.active_status == 5">Delivered</span>
                </h2>
              </div>
              <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1">
                <h2 class="font-medium mt-2 mr-auto"><b>Final Module</b> : {{ pagedata.request_details.request_item.final_quantity }}</h2>
              </div>
              <!-- <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1"></div> -->
              <!-- <div class="col-span-12 md:col-span-6 xl:col-span-5 py-1">
                <h2 class="text-lg mt-2 mr-auto"><b>Accepted Date</b> : {{ pagedata.request_details.request_item.accepted_at ? pagedata.request_details.request_item.accepted_at : '-' }}</h2>
              </div> -->
              <div class="col-span-12 md:col-span-6 xl:col-span-5 py-1">
                <h2 class="font-medium mt-2 mr-auto"><b>Outward Date</b> : {{ pagedata.request_details.request_item.outward_date ? pagedata.request_details.request_item.outward_date : '-' }}</h2>
              </div>
              <div class="xl:col-span-2"></div>
            </div>

            <div class="border mt-5 p-0 shadow-xl">

              <div class="overflow-x-auto tableFixHead">
                <table class="w-full text-center">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="border-b py-2 px-2 dark:border-dark-5">#</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Material</th>
                      <!-- <th class="border-b py-2 px-2 dark:border-dark-5">Specification</th> -->
                      <th class="border-b py-2 px-2 dark:border-dark-5">Type</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Per Module <br> Qantity</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Quantity</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5"></th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item, index) in pagedata.request_details.request_products" :key="index">
                      <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                      <td class="border-b py-2 px-2">{{ item.product_name }}</td>
                      <!-- <td class="border-b py-2 px-2">{{ item.description }}</td> -->
                      <td class="border-b py-2 px-2">{{ item.product_type == 1 ? 'Poly' : 'MBB' }}</td>
                      <td class="border-b py-2 px-2">{{ item.quantity_per ? $h.myFloat(item.quantity_per) + (item.main_uqc_name ? item.main_uqc_name : '') : '-' }}</td>
                      <td class="border-b py-2 px-2">{{ item.first_quantity ? item.first_quantity : '-' }}</td>
                      <td class="border-b py-2 px-2">{{ item.second_quantity ? item.second_quantity : '-' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="pagedata.request_details.request_products.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!--- END: Consumption Products Model --->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref, reactive, onMounted } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import axios from 'axios'
import { BASE_URL } from '@/constant/config.js'
import cash from 'cash-dom'
import moment from 'moment'
import { helper as $h } from '@/utils/helper'

export default defineComponent({
  setup() {
    const tableRef = ref()
    const tabulator = ref()
    const pagedata = reactive({
      watt_details: [],
      party_details: [],
      request_details: {
        request_item:[],
        request_products: []
      },
    })
    const outward_filters = reactive({
      party_id: "0",
      watt_id: "0",
      pannel_type: "0",
      request_code: "",
      s_date:moment().startOf('month').format('YYYY-MM-DD'),
      e_date:moment().format('YYYY-MM-DD'),
      page_size: '1'
    })
    const print_filters = reactive({
      party_id: "0",
      watt_id: "0",
      pannel_type: "0",
      request_code: "",
      s_date:moment().startOf('month').format('YYYY-MM-DD'),
      e_date:moment().format('YYYY-MM-DD')
    })


    const initTabulator = () => {

      print_filters.party_id = outward_filters.party_id
      print_filters.watt_id = outward_filters.watt_id
      print_filters.pannel_type = outward_filters.pannel_type
      print_filters.request_code = outward_filters.request_code
      print_filters.s_date = outward_filters.s_date
      print_filters.e_date = outward_filters.e_date

      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/report/outward-report-details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams: {
          "party_id": print_filters.party_id == "0" ? "" : window.btoa(print_filters.party_id),
          "watt_id": print_filters.watt_id == "0" ? "" : window.btoa(print_filters.watt_id),
          "pannel_type": print_filters.pannel_type == "0" ? "" : window.btoa(print_filters.pannel_type),
          "request_code": print_filters.request_code == "" ? "" : window.btoa(print_filters.request_code),
          "s_date": print_filters.s_date == "" ? "" : window.btoa(print_filters.s_date),
          "e_date": print_filters.e_date == "" ? "" : window.btoa(print_filters.e_date),
        },
        // pagination: 'remote',
        // paginationSize: 20,
        // renderHorizontal:"virtual",
        // paginationSizeSelector: [20, 30, 40,60],
        ajaxFiltering: true,
        ajaxSorting: true,
        headerSort: false,
        printAsHtml: true,
        printStyled: true,
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [

          // For HTML table
          {
            title: 'OW NO',
            minWidth: 100,
            responsive: 0,
            field: 'request_code',
            hozAlign:'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter: "textarea"
          },
          // {
          //   title: 'Party',
          //   minWidth: 100,
          //   responsive: 0,
          //   field: 'companey_name',
          //   hozAlign:'center',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,
          //   formatter: "textarea"
          // },
          {
            title: 'Watt',
            minWidth: 100,
            responsive: 0,
            field: 'watt',
            hozAlign:'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter: "textarea"
          },
          {
            title: 'Type',
            minWidth: 100,
            responsive: 0,
            field: 'product_type',
            hozAlign:'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter: "textarea"
          },
          {
            title: 'Request <br> Modules',
            minWidth: 100,
            responsive: 0,
            field: 'quantity',
            hozAlign:'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter: "textarea"
          },
          {
            title: 'Final <br> Modules',
            minWidth: 100,
            responsive: 0,
            field: 'final_quantity',
            hozAlign:'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter: "textarea"
          },
          {
            title: 'Outward Date',
            minWidth: 150,
            responsive: 0,
            field: 'outward_date',
            hozAlign:'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter:"textarea",
          },
          {
            title: 'Actions',
            minWidth: 100,
            field: 'actions',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              const a = cash(`<div class="flex lg:justify-center items-center">
                <button class="btn btn-primary py-1">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> View
                </button>

              </div>`)
              cash(a).on('click', function() {
                request_products(cell.getData().id)
              })

              return a[0]
            }
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })



      sessionStorage.setItem('outward_report_filters',JSON.stringify(outward_filters))
    }


    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Get Request Product Details
    const request_products = async (id) => {

      const params = {
        id: window.btoa(id)
      };

      cash('#product-details-modal').modal('show')
      let promise = axios({
        url: "api/report/outward-material-report-details",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.request_details.request_item = data.request
        pagedata.request_details.request_products = data.items


      })
      .catch(_error => {

      });
    }
    // Get Product Details
    const sizeDetails = async () => {

      const params = {
      };

      let promise = axios({
        url: "api/master/dp_size",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.watt_details = data.items

      })
      .catch(_error => {

        pagedata.watt_details = []

      });
    }

    // Get Material Details
    const partyDetails = async () => {

      const params = {
      };

      let promise = axios({
        url: "api/master/dp_outward_party",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.party_details = data.items

      })
      .catch(_error => {

        pagedata.party_details = []

      });
    }

    // On reset filter
    const resetStockFilters = () => {
      outward_filters.party_id = "0"
      outward_filters.watt_id = "0"
      outward_filters.pannel_type = "0"
      outward_filters.request_code = ""
      outward_filters.s_date =moment().startOf('month').format('YYYY-MM-DD')
      outward_filters.e_date =moment().format('YYYY-MM-DD')
      outward_filters.page_size = 1

      initTabulator()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {

      let filter_items = sessionStorage.getItem('outward_report_filters') != null ? JSON.parse(sessionStorage.getItem('outward_report_filters')) : null

      if(filter_items != null) {
        outward_filters.party_id = filter_items.party_id
        outward_filters.watt_id = filter_items.watt_id
        outward_filters.pannel_type = filter_items.pannel_type
        outward_filters.request_code = filter_items.request_code
        outward_filters.s_date = filter_items.s_date
        outward_filters.e_date = filter_items.e_date
        outward_filters.page_size = filter_items.page_size

      }

      sizeDetails()
      partyDetails()
      initTabulator()
      reInitOnResizeWindow()
    })

    return {
      tableRef,pagedata,outward_filters,print_filters,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      initTabulator,
      resetStockFilters,
    }
  }
})
</script>
<style scoped>
.tableFixHead {
        overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
        max-height: 700px; /* gives an initial height of 200px to the table */
      }
      .tableFixHead thead {
        position: sticky; /* make the table heads sticky */
        top: 0px; /* table head will be placed from the top of the table and sticks to it */
      }
</style>
