<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      </div>
    </div>
    <div class="pos intro-y grid gap-5 my-8">
      <!-- BEGIN: Item List -->
      <div class="intro-y col-span-12 lg:col-span-8">
        <div class="grid grid-cols-12 gap-5 mt-5">
          <template v-for="(master,index) in masters" :key="index">
            <div class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in" v-if="master.roles.includes(userDetails.user_type)">
            <router-link class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in px-6" :to="master.to"
            >
                <div class="font-medium text-base text-center">{{ master.name }}</div>
            </router-link>
            </div>
          </template>
        </div>
      </div>
      <!-- END: Item List -->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import store from '@/store'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const userDetails = computed(() => store.state.user.currentUser)
    const masters = [
    {
        name: 'Stock Consumptions',
        to: { name: 'report-stock-consumption' },
        roles: [1,3,4]
      },
    {
        name: 'Material Wise Stock',
        to: { name: 'report-material-wise-stock' },
        roles: [1,3,4]
      },
      {
        name: 'Module Outward',
        to: {name: 'outward-report'},
        roles: [1,3,4]
      },
      {
        name: 'OW Report',
        to: { name: 'invoice-wise-outward-report' },
        roles: [1,3,4]
      },
      {
        name: 'Party Wise Outward',
        to: { name: 'party-wise-outward-report' },
        roles: [1,3,4]
      },
      {
        name: 'Fail Material',
        to: { name: 'report-fail-material' },
        roles: [1,2,3,4]
      },
    ]
    // const accesses = computed(() => store.state.user.actions)
    return {
      masters,
      userDetails
      // accesses,
    }
  }
})
</script>
