<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Stock Details</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

            <!-- <a @click="printReportDetails" class="btn btn-primary shadow-md mr-2">Print</a> -->

        </div>
    </div>
    <div class="intro-y box p-5 mt-5">

        <div id="responsive-table" class="p-5" v-if="page_data.loading == true">
            <div class="preview">
                <div class="overflow-x-auto">
                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Records</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div v-if="page_data.generate == 'gen'">
                <div class="grid grid-cols-12 gap-x-2">
                    <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1">
                        <h5 class="font-medium mt-2 mr-auto"><b>OW NO</b> : {{ page_data.request_details.request_item.request_code }}</h5>
                    </div>
                    <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1">
                        <h5 class="font-medium mt-2 mr-auto"><b>Watt</b> : {{ page_data.request_details.request_item.watt }}</h5>
                    </div>
                    <!-- <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1">
                        <h5 class="font-medium mt-2 mr-auto"><b>Party</b> : {{ page_data.request_details.request_item.companey_name }}</h5>
                    </div> -->
                    <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1">
                        <h5 class="font-medium mt-2 mr-auto"><b>Module Stock</b> : {{ page_data.request_details.request_item.quantity }}</h5>
                    </div>
                    <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1">
                        <h5 class="font-medium mt-2 mr-auto"><b>Current Stock</b> : {{ page_data.request_details.request_item.cr_quantity }}</h5>
                    </div>
                    <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1">
                      <h5 class="font-medium mt-2 mr-auto"><b>Module Type</b> : {{ page_data.request_details.request_item.product_type == 1 ? 'Poly' : 'MBB' }}</h5>
                    </div>
                    <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1">
                        <h5 class="font-medium mt-2 mr-auto"><b>Stock Date</b> : {{ page_data.request_details.request_item.issue_date }}</h5>
                    </div>
                    <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1" v-if="page_data.request_details.request_item.active_status">
                        <h5 class="font-medium mt-2 mr-auto"><b>Status</b> :
                            <span class="text-theme-1" v-if="page_data.request_details.request_item.active_status == 1">Available</span>
                            <span class="text-theme-12" v-if="page_data.request_details.request_item.active_status == 2">Sold</span>
                            <span class="text-theme-6" v-if="page_data.request_details.request_item.active_status == 3">Cancelled</span>
                        </h5>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="intro-y box p-5 mt-5">
        <div class="flex items-center justify-between">
            <h2 class="text-lg font-medium mr-auto">Request Materials</h2>
            <div v-if="userDetails.user_type == 1">
                <button type="button" class="btn btn-danger mr-1" @click="reject_direct" v-if="(page_data.request_details.request_item.active_status == 2 && page_data.request_details.request_item.duty_status == 1)">
                    Reject Request
                </button>
                <button type="button" class="btn btn-danger mr-1" @click="reject_direct" v-if="page_data.request_details.request_item.active_status == 1">
                    Reject Request
                </button>
                <button type="button" class="btn btn-danger mr-1" @click="reject_duty_request" v-if="(page_data.request_details.request_item.active_status == 2 && page_data.request_details.request_item.duty_status == 2)">
                    Reject Duty
                </button>
                <!-- <button type="button" class="btn btn-danger mr-1" @click="reject_duty_request" v-if="(page_data.request_details.request_item.active_status == 2 && page_data.request_details.request_item.duty_status == 2) || page_data.request_details.request_item.active_status == 4">
                    Reject Duty
                </button> -->
                <button type="button" class="btn btn-primary mr-1" :disabled="page_data.request_loading == true || page_data.request_details.request_item.request_accept == false" @click="accept_request_all" v-if="page_data.request_details.request_item.active_status == 1">
                    Accept Request
                    <LoadingIcon v-if="page_data.request_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
                <button type="button" class="btn btn-primary" :disabled="page_data.request_loading" @click="send_duty_request" v-if="page_data.request_details.request_item.active_status == 2 && page_data.request_details.request_item.duty_status == 1">
                    Duty Request
                    <LoadingIcon v-if="page_data.request_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
            </div>
            <div v-if="userDetails.user_type == 3 || userDetails.user_type == 1">
                <button type="button" class="btn btn-primary" :disabled="page_data.request_loading" @click="openModal" v-if="page_data.request_details.request_item.active_status == 4">
                    Deliver
                    <LoadingIcon v-if="page_data.request_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>

            </div>
        </div>
        <div class="border mt-5 p-0 shadow-md">

            <div class="overflow-x-auto">
                <table class="w-full text-center text-xs">
                    <thead>
                        <tr class="bg-theme-14">
                            <th class="border-b py-2 px-2 dark:border-dark-5">#</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5">Material</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5">Type</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5">Per Module <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5">Required <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5">Accepted <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5">Duty Paid <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5">OOC Pending <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5">Duty Pending <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5">Duty Request <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5">Status</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5" v-if="userDetails.user_type == 1">Purchase Details</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item, index) in page_data.request_details.request_products" :key="index">
                            <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                            <td class="border-b py-2 px-2">{{ item.product_name }}</td>
                            <td class="border-b py-2 px-2">{{ item.product_type == 1 ? 'Poly' : (item.product_type == 2 ? 'MBB' : 'Both') }}</td>
                            <td class="border-b py-2 px-2">{{ item.quantity_per ? $h.myFloat(item.quantity_per) + (item.uqc_name ? item.uqc_name : '') : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.quantity_per ? $h.myFloat(item.quantity_per * (page_data.request_details.request_item.final_quantity > 0 ? page_data.request_details.request_item.final_quantity : 0)) + (item.uqc_name ? item.uqc_name : '') : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.quantity ? $h.myFloat(item.quantity) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.duty_paid_qty ? $h.myFloat(item.duty_paid_qty) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.duty_ooc_qty ? $h.myFloat(item.duty_ooc_qty) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.duty_pending_qty ? $h.myFloat(item.duty_pending_qty) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.duty_request_qty ? $h.myFloat(item.duty_request_qty) : '-' }}</td>
                            <!-- <td class="border-b py-2 px-2" :class="{'text-primary-3' : item.quantity_diff < 0,'text-primary-4' : item.quantity_diff > 0}">{{ item.quantity_diff > 0 ? '+' : '' }} {{ $h.myFloat(item.quantity_diff) }}</td> -->
                            <td class="border-b py-2 px-2">
                                <div class="flex items-center lg:justify-center ${
                    cell.getData().active_status == 1 ? 'text-theme-9' : 'text-theme-6'
                  }" :class="{'text-theme-9' : item.product_status != 'Cancelled','text-theme-6' : item.product_status == 'Cancelled' || item.product_status == 'Pending'}">
                                    <i data-feather="check-square" class="w-4 h-4 mr-2"></i> {{ item.product_status }}
                                </div>
                            </td>
                            <td class="border-b py-2 px-2" v-if="userDetails.user_type == 1">
                                <button class="btn btn-primary px-2 py-1" :disabled="item.active_status == 3" @click="get_purchase_itms(item.id,(item.quantity_per * (page_data.request_details.request_item.final_quantity ? page_data.request_details.request_item.final_quantity : 0)))">Purchases</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="page_data.request_details.request_products.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
            </div>
        </div>

    </div>
    <div class="intro-y box p-5 mt-5">
        <h2 class="text-lg font-medium mr-auto">Purchase Materials Details</h2>
        <div class="border mt-5 p-0 shadow-md">

            <div class="overflow-x-auto tableFixHead">
                <table class="w-full text-center text-xs">
                    <thead>
                        <tr class="bg-theme-14">
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">#</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Purchase</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Bill / Date</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Material</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Specification</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Type</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Purchase <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Accepted <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">UQC</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Duty Paid <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">OOC Pending <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Duty Pending <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Duty Request <br> Quantity</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Accessible <br> Value</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">BCD</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">IGST</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Comp. cess</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">DutyPaid <br> Amount</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Clearance</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Company Name</th>
                            <th class="border-b py-2 px-2 dark:border-dark-5 whitespace-nowrap">Warehouse <br> DateTime</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item, index) in page_data.request_details.product_purchases" :key="index">
                            <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                            <td class="border-b py-2 px-2">{{ item.purchase_code }}</td>
                            <td class="border-b py-2 px-2 whitespace-nowrap">{{ item.bill_no ? item.bill_no : '-' }} <br> {{ item.bill_date ? item.bill_date : '' }}</td>
                            <td class="border-b py-2 px-2">{{ item.product_name }}</td>
                            <td class="border-b py-2 px-2">{{ item.description }}</td>
                            <td class="border-b py-2 px-2">{{ item.product_type == 1 ? 'Poly' : (item.product_type == 2 ? 'MBB' : 'Both') }}</td>
                            <td class="border-b py-2 px-2">{{ item.purchase_qty ? $h.myFloat(item.purchase_qty) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.quantity ? $h.myFloat(item.quantity) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.uqc_name ? item.uqc_name : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.duty_paid_qty ? $h.myFloat(item.duty_paid_qty) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.duty_ooc_qty ? $h.myFloat(item.duty_ooc_qty) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.duty_pending_qty ? $h.myFloat(item.duty_pending_qty) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.duty_request_qty ? $h.myFloat(item.duty_request_qty) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.accessable_value ? $h.myFloat(item.accessable_value) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.bcd_value ? $h.myFloat(item.bcd_value) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.igst_value ? $h.myFloat(item.igst_value) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.comp_cess_value ? $h.myFloat(item.comp_cess_value) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.duty_paid_amount ? $h.myFloat(item.duty_paid_amount) : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.purchase_clearance ? item.purchase_clearance : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.company_name ? item.company_name : '-' }}</td>
                            <td class="border-b py-2 px-2">{{ item.warehouse_receipt_time ? item.warehouse_receipt_time : '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="page_data.request_details.request_products.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
            </div>
        </div>

    </div>
    <!-- END: HTML Table Data -->

    <!--- BEGIN: Product Purchases Model --->
    <div id="purchase-details-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog-lg">
            <div class="modal-content w-full">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Product Purchase Details <span v-if="page_data.product_details.total.cr_qty < page_data.product_details.required_quantity"><span class="text-theme-6 ml-4">** You may not have enough quantity to select purchase ! **</span></span></h2>
                        <div>
                            <a data-dismiss="modal" href="javascript:;">
                                <XIcon class="w-8 h-8 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body py-5 px-5">
                    <div class="grid grid-cols-12 gap-x-2">
                        <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1">
                            <h2 class="text-lg mt-2 mr-auto"><b>Material</b> : {{ page_data.product_details.product_detail.product_name }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1">
                            <h2 class="text-lg mt-2 mr-auto"><b>Type</b> : {{ page_data.product_details.product_detail.product_type == 1 ? 'Poly' : (page_data.product_details.product_detail.product_type == 2 ? 'MBB' : 'Both') }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1" v-if="page_data.product_details.product_detail.active_status">
                            <h2 class="text-lg mt-2 mr-auto"><b>Status</b> :
                                <span class="text-theme-1" v-if="page_data.product_details.product_detail.active_status == 1">Pending</span>
                                <span class="text-theme-3" v-if="page_data.product_details.product_detail.active_status == 2">Duty Pending</span>
                                <span class="text-theme-6" v-if="page_data.product_details.product_detail.active_status == 3">Rejected</span>
                                <span class="text-theme-9" v-if="page_data.product_details.product_detail.active_status == 4">Accepted</span>
                                <span class="text-theme-9" v-if="page_data.product_details.product_detail.active_status == 5">Delivered</span>
                            </h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1">
                            <h2 class="text-lg mt-2 mr-auto"><b>Request Quantity</b> : {{ page_data.product_details.required_quantity }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1">
                            <h2 class="text-lg mt-2 mr-auto"><b>Selected Quantity</b> : {{ page_data.product_details.selected_quantity }}</h2>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-x-2 mt-3">
                        <div class="col-span-2 py-1">
                          <div>
                            <TomSelect
                              v-model="page_data.filter_data.material_id"
                                :options="{
                                  placeholder: 'Select Material'
                                }"
                                class="w-full"
                              >
                              <option value="0">Select Material</option>
                              <option :value="item.id" v-for="item in page_data.filter_data.material_arr" :key="item.id">{{ item.description }}</option>
                            </TomSelect>

                          </div>
                        </div>
                        <div class="col-span-2 py-1">
                          <div>
                            <TomSelect
                              v-model="page_data.filter_data.status"
                                :options="{
                                  placeholder: 'Select Status'
                                }"
                                class="w-full"
                              >
                              <option value="0">All</option>
                              <option value="1">Duty Paid</option>
                              <option value="2">Duty Pending</option>
                            </TomSelect>

                          </div>
                        </div>
                        <div class="col-span-1 py-1">
                            <button class="btn btn-primary px-2" @click="request_product_purchase_single">Go</button>
                        </div>
                    </div>

                    <div class="border mt-5 p-0 shadow-md">

                        <div class="overflow-x-auto tableFixHead">
                            <table class="w-full text-xs text-center">
                                <thead>
                                    <tr class="bg-theme-14 z-50">
                                        <th class="border-b py-2 px-2 dark:border-dark-5">#</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Purchase</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Bill / Date</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Material <br> Specification</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Required <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Cr Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Purchase <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">UQC</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty Paid <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">OOC Pending <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty Pending <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty Request <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Accessible <br> Value</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">BCD</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">IGST</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Comp. cess</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Est Duty <br> Amount</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">DutyPaid <br> Amount</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Total Duty <br> Amount</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Clearance</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Company Name</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Warehouse <br> DateTime</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Action</th>
                                    </tr>
                                    <tr class="bg-gray-200" v-if="page_data.product_details.purchase_details.length != 0">
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2">{{ page_data.product_details.total.cr_new_qty }}</td>
                                        <td class="border-b py-2 px-2"><span :class="{'text-theme-6' : page_data.product_details.total.cr_qty < page_data.product_details.required_quantity }">{{ $h.myFloat(page_data.product_details.total.cr_qty) }}</span></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2">{{ (page_data.product_details.total.cr_qty_paid ? $h.myFloat(page_data.product_details.total.cr_qty_paid) : '') }}</td>
                                        <td class="border-b py-2 px-2">{{ (page_data.product_details.total.cr_qty_duty_request ? $h.myFloat(page_data.product_details.total.cr_qty_duty_request) : '') }}</td>
                                        <td class="border-b py-2 px-2">{{ (page_data.product_details.total.cr_qty_pending ? $h.myFloat(page_data.product_details.total.cr_qty_pending) : '') }}</td>
                                        <td class="border-b py-2 px-2">{{ (page_data.product_details.total.cr_qty_ooc_pending ? $h.myFloat(page_data.product_details.total.cr_qty_ooc_pending) : '') }}</td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2">{{ (page_data.product_details.total.est_duty_amount ? $h.myFloat(page_data.product_details.total.est_duty_amount) : '') }}</td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0 && item.theme == ''}" v-for="(item, index) in page_data.product_details.purchase_details" :key="index">
                                        <td class="border-b py-2 px-2">
                                            <div class="flex items-center">
                                                <input type="checkbox" class="p-2 form-check" v-model="item.selected" @change="update_quantity(item)" :disabled="page_data.product_details.limit_touch == true && item.selected == false">
                                                <span class="ml-2" :class="{'text-red' : (item.cr_qty_pending > 0 || item.cr_qty_duty_request > 0) && item.purchase_type == 1 ,'text-green' : ((item.cr_qty_pending == 0 || item.cr_qty_pending == null) && (item.cr_qty_duty_request == 0 || item.cr_qty_duty_request == null) && item.purchase_type == 1)}"> {{ index + 1 }}</span>
                                            </div>
                                        </td>
                                        <td class="border-b py-2 px-2" :class="{'text-red' : (item.cr_qty_pending > 0 || item.cr_qty_duty_request > 0) && item.purchase_type == 1 ,'text-green' : ((item.cr_qty_pending == 0 || item.cr_qty_pending == null) && (item.cr_qty_duty_request == 0 || item.cr_qty_duty_request == null) && item.purchase_type == 1)}">{{ item.purchase_code }}</td>
                                        <td class="border-b py-2 px-2 whitespace-nowrap">{{ item.bill_no ? item.bill_no : '-' }} <br> {{ item.bill_date ? item.bill_date : '' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.description ? item.description : '-' }}</td>
                                        <td class="border-b py-2 px-2"><input type="number" name="required_quantity" id="required_quantity" autocomplete="off" @change="validate_quantity(item)" :disabled="item.selected == false" class="form-control w-20 text-center" v-model="item.cr_new_qty" placeholder="Req Qty"></td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty ? $h.myFloat(item.cr_qty) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.purchase_qty ? $h.myFloat(item.purchase_qty) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.uqc_name ? $h.myFloat(item.uqc_name) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty_paid ? $h.myFloat(item.cr_qty_paid) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty_ooc_pending ? $h.myFloat(item.cr_qty_ooc_pending) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty_pending ? $h.myFloat(item.cr_qty_pending) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty_duty_request ? $h.myFloat(item.cr_qty_duty_request) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.accessable_value ? $h.myFloat(item.accessable_value) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.bcd_value ? $h.myFloat(item.bcd_value) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.igst_value ? $h.myFloat(item.igst_value) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.comp_cess_value ? $h.myFloat(item.comp_cess_value) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ (item.per_unit_qty > 0 && item.cr_new_qty > 0) ? $h.myFloat(item.per_unit_qty * item.cr_new_qty) : 0 }}</td>
                                        <td class="border-b py-2 px-2">{{ item.duty_paid_amount ? item.duty_paid_amount : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ (item.duty_pay_amount > 0) ? $h.myFloat(item.duty_pay_amount) : 0 }}</td>
                                        <td class="border-b py-2 px-2">{{ item.purchase_clearance ? item.purchase_clearance : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.company_name ? item.company_name : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.warehouse_receipt_time ? item.warehouse_receipt_time : '-' }}</td>
                                        <td class="border-b py-2 px-2">
                                          <button class="btn btn-primary btn-sm p-2 w-24 flex-nowrap" @click="send_direct_duty_request(item)" :disabled="(item.cr_qty_pending == 0 || item.cr_qty_pending == null) || (((item.cr_qty_duty_request ? item.cr_qty_duty_request : 0) - (item.cr_qty_pending ? item.cr_qty_pending : 0)) >= 0)">
                                              Direct Duty
                                          </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary mr-1 mr-3">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-danger mr-3" :disabled="page_data.purchase_loading" v-if="page_data.product_details.product_detail.active_status == 2 && page_data.product_details.product_detail.duty_status == 1" @click="add_single_reset_request_quantity(page_data.product_details.product_detail.id,page_data.product_details.required_quantity)">
                        Reset
                        <LoadingIcon v-if="page_data.purchase_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                    <button type="button" class="btn btn-primary mr-3" v-if="page_data.product_details.product_detail.active_status == 1 && page_data.product_details.product_detail.duty_status == 1" :disabled="page_data.purchase_loading" @click="add_single_product_request_quantity">
                        Accept Purchase
                        <LoadingIcon v-if="page_data.purchase_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                    <button type="button" class="btn btn-primary" v-if="page_data.product_details.product_detail.active_status == 2 && page_data.product_details.product_detail.duty_status == 1" :disabled="page_data.purchase_loading" @click="add_single_product_request_quantity">
                        Update Purchase
                        <LoadingIcon v-if="page_data.purchase_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!--- END: Product Purchases Model --->
    <!--- BEGIN: Duty Request Success Modal --->
    <div id="duty-request-success-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-body py-10 px-5">
                    <div class="p-5 text-center">
                        <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
                        <div class="text-3xl mt-5">Duty Request Sent Successfully!</div>
                    </div>

                    <h2 class="text-lg font-medium mr-auto my-3">{{ page_data.message }}</h2>
                    <div class="border mt-5 p-0 shadow-xl">

                        <div class="overflow-x-auto tableFixHead">
                            <table class="w-full text-center">
                                <thead>
                                    <tr class="bg-theme-14">
                                        <th class="border-b py-2 px-2 dark:border-dark-5">#</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Product</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Material</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Type</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty <br> Paid <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty <br> Pending <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Direct Duty <br> Request <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Custom Duty <br> Request <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Custom Duty <br> Amount</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0 && item.theme == ''}" v-for="(item, index) in page_data.duty_assigned_arr" :key="index">
                                        <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                        <td class="border-b py-2 px-2">{{ item.product_name }}</td>
                                        <td class="border-b py-2 px-2">{{ item.description }}</td>
                                        <td class="border-b py-2 px-2">{{ item.product_type }}</td>
                                        <td class="border-b py-2 px-2">{{ item.quantity ? $h.myFloat(item.quantity) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.duty_paid ? $h.myFloat(item.duty_paid) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.duty_pending ? $h.myFloat(item.duty_pending) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.direct_duty_request ? $h.myFloat(item.direct_duty_request) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.custom_duty_request ? $h.myFloat(item.custom_duty_request) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.custom_duty_amount ? $h.myFloat(item.custom_duty_amount) : '-' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div v-if="page_data.product_purchase_detail.purchases.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
              </div> -->
                    </div>
                    <div class="px-5 py-8 text-right">
                        <button type="button" data-dismiss="modal" class="btn btn-primary w-24">
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--- END: Product Purchases Model --->
    <!-- BEGIN: Modal Content -->
    <div id="reference-number-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- BEGIN: Modal Header -->
                <div class="modal-header">
                    <h2 class="font-medium text-base mr-auto">
                        Confirm Invoice Number
                    </h2>
                </div>
                <!-- END: Modal Header -->
                <!-- BEGIN: Modal Body -->
                <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                    <div class="col-span-12">
                        <div class="text-3xl mt-5 text-center">Are you sure?</div>
                        <div class="text-gray-600 mt-2 text-center">
                            Please note that this will automatically <br>
                            update Invoice Number, <br>
                        </div>
                    </div>
                    <!-- <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Party Name</label>
                        <input id="ref_no" type="text" v-model="page_data.party_name" class="form-control" autocomplete="off" placeholder="Invoice No" />
                    </div> -->
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Quantity</label>
                        <input id="ref_no" type="text" v-model="page_data.out_quantity" class="form-control" autocomplete="off" placeholder="Invoice No" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Invoice Number</label>
                        <input id="ref_no" type="text" v-model="page_data.ref_no" class="form-control" autocomplete="off" placeholder="Invoice No" />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Outward Date</label>
                        <input
                          type= "date"
                          id="update-profile-form-13"
                          class="form-control"
                          placeholder=""
                          v-model="page_data.date"
                        />
                    </div>
                    <div class="col-span-12">
                        <div class="text-black mt-2"><b>Please confirm the Invoice No !</b></div>
                    </div>
                </div>
                <!-- END: Modal Body -->
                <!-- BEGIN: Modal Footer -->
                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-28 mr-1">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" :disabled="page_data.request_loading" @click="outward_module_request">
                        Submit
                        <LoadingIcon v-if="page_data.request_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                </div>
                <!-- END: Modal Footer -->
            </div>
        </div>
    </div>
    <!-- END: Modal Content -->

</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject,
    computed
} from 'vue'
import {
    BASE_URL
} from '@/constant/config.js'
import moment from 'moment';
import {
    useRoute,
    useRouter
} from 'vue-router';
import axios from 'axios';
import {
    helper as $h
} from '@/utils/helper';
import store from '@/store';

export default defineComponent({
    setup() {

        const swal = inject('$swal');
        const route = useRoute()
        const router = useRouter()
        const userDetails = computed(() => store.state.user.currentUser)
        const page_data = reactive({
            ref_no: '',
            party_name: '',
            out_quantity: '',
            date: '',
            request_details: {
                request_item: [],
                request_products: [],
                product_purchases: [],
                new_quantity: ''
            },
            product_details: {
                product_detail: [],
                purchase_details: [],
                total: [],
                selected_quantity: 0,
                required_quantity: 0,
                limit_touch: false,
            },
            loading: false,
            purchase_loading: false,
            request_loading: false,
            generate: "no",
            filter_data: {
                material_arr: [],
                material_id: "0",
                status: "",
                id: "",
                qty: ""
            },

            request_id: route.params.id
        })

        const get_request_details = async () => {
            const params = {
                id: page_data.request_id
            };

            page_data.loading = true;
            let promise = axios({
                url: "api/get_single_request_detail_by_req",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.request_details.request_item = data.request
                    page_data.request_details.new_quantity = page_data.request_details.request_item.final_quantity
                    page_data.request_details.request_products = data.items
                    page_data.request_details.product_purchases = data.product_purchases
                    page_data.loading = false;
                    page_data.generate = "gen";
                })
                .catch(_error => {

                    page_data.loading = false;
                });
        }

        // Get Purchase Details For Select Purchase
        const request_product_purchase_single = async () => {
            const params = {
                id: window.btoa(page_data.filter_data.id),
                material_id: page_data.filter_data.material_id != "0" ? window.btoa(page_data.filter_data.material_id) : '',
                status: page_data.filter_data.status != "0" ? window.btoa(page_data.filter_data.status) : '',
            };


            cash('#purchase-details-modal').modal('show')
            // page_data.product_details.product_detail = []
            // page_data.product_details.required_quantity = 0
            // page_data.product_details.purchase_details = []
            // page_data.product_details.total = []
            let promise = axios({
                url: "api/get_out_purchase_details",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.product_details.product_detail = data.product_item
                    page_data.product_details.required_quantity = page_data.filter_data.qty
                    page_data.product_details.purchase_details = data.items
                    page_data.product_details.total = data.total
                    page_data.product_details.selected_quantity = 0

                    get_material_list(page_data.product_details.product_detail.product_id)

                    if (page_data.product_details.purchase_details.length > 0) {
                        calculate_update_quantity()
                    }

                })
                .catch(_error => {

                });
        }
        const get_material_list = async (id) => {
            //
            const params = {
                "product_id": window.btoa(id),
            };
            let promise = axios({
                url: "api/master/get_details_for_tax",
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.filter_data.material_arr = data.items;
                    // page_data.filter_data.material_id = "0"
                    // page_data.filter_data.status = "0"
                }).catch(_error => {

                    page_data.filter_data.material_arr = [];
                    // page_data.filter_data.material_id = "0"
                    // page_data.filter_data.status = "0"

                });
        }

        // Update Quantity
        const update_quantity = async (item) => {

            if (item.selected == true) {

                let remain_qty = (page_data.product_details.required_quantity - page_data.product_details.selected_quantity)

                if (item.cr_qty >= remain_qty) {
                    item.cr_new_qty = $h.myFloat(remain_qty)
                } else {
                    item.cr_new_qty = $h.myFloat(item.cr_qty)
                }
            } else {
                item.cr_new_qty = 0
            }

            calculate_update_quantity()
        }
        const validate_quantity = (item) => {
            if (item.cr_new_qty > item.cr_qty) {
                item.cr_new_qty = 0
                swal("Warning", "Quantity must less then Current Quantity !", "warning");
            } else {}
            calculate_update_quantity()
        }

        const calculate_update_quantity = () => {

            if (page_data.product_details.purchase_details.length > 0) {

                page_data.product_details.selected_quantity = 0
                page_data.product_details.total.cr_new_qty = 0
                page_data.product_details.total.est_duty_amount = 0
                page_data.product_details.purchase_details.forEach(element => {
                    if (element.selected == true) {
                        page_data.product_details.selected_quantity += $h.myFloat(element.cr_new_qty)
                        page_data.product_details.total.cr_new_qty += $h.myFloat(element.cr_new_qty)
                        page_data.product_details.total.est_duty_amount += $h.myFloat(element.cr_new_qty * (element.per_unit_qty ? element.per_unit_qty : 0))
                    }
                });

                if (page_data.product_details.selected_quantity >= page_data.product_details.required_quantity) {
                    page_data.product_details.limit_touch = true
                } else {
                    page_data.product_details.limit_touch = false
                }

            }
        }

        // Update Single Item
        const add_single_product_request_quantity = async () => {

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Add Product Purchase!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let purchase_arr = []

                    let accessable_qty = page_data.product_details.selected_quantity
                    if (page_data.product_details.required_quantity < page_data.product_details.selected_quantity) {

                        swal({
                            title: "Warning!",
                            icon: "warning",
                            text: "Selected Quantity is Greater then Required Quantity",
                            type: "warning",
                            confirmButtonColor: "#064e3b",
                        })

                        return
                    }
                    if (page_data.product_details.required_quantity > page_data.product_details.selected_quantity) {

                        swal({
                            title: "Warning!",
                            icon: "warning",
                            text: "Request must be filled with required quantity !",
                            type: "warning",
                            confirmButtonColor: "#064e3b",
                        })

                        return
                    }

                    if (page_data.request_details.request_item.active_status == 2 && page_data.request_details.request_item.duty_status == 1) {
                        if (page_data.product_details.required_quantity > page_data.product_details.selected_quantity) {

                            swal({
                                title: "Warning!",
                                icon: "warning",
                                text: "Selected Quantity is Less then Required Quantity",
                                type: "warning",
                                confirmButtonColor: "#064e3b",
                            })

                            return
                        }
                    }

                    page_data.product_details.purchase_details.forEach(element => {
                        if (element.selected == true) {
                            purchase_arr.push(element)
                        }
                    });

                    let new_url = "api/set_single_product_purchase_details_new"

                    page_data.purchase_loading = true

                    const params = {
                        "request_item": page_data.product_details.product_detail,
                        "purchase_items": purchase_arr,
                        "required_quantity": page_data.product_details.required_quantity,
                        "accessable_quantity": accessable_qty,
                        "final_quantity": page_data.request_details.request_item.final_quantity,
                        // "request_item_main": page_data.request_details.request_item,
                        // "product_items": page_data.request_details.request_products,
                        //   "new_quantity": page_data.request_details.new_quantity,
                    };

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {

                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                page_data.purchase_loading = false
                                return;
                            }

                            swal({
                                title: "Success!",
                                icon: "success",
                                text: data.message,
                                type: "success",
                                confirmButtonColor: "#064e3b",
                            })

                            // request_products_new(params.request_item_main.id, params.new_quantity)
                            get_request_details()
                            cash('#purchase-details-modal').modal('hide')

                            page_data.purchase_loading = false
                        })
                        .catch(_error => {

                            page_data.purchase_loading = false

                        });
                }
            });

        }
        // Update Single Item
        const add_single_reset_request_quantity = async (id, qty) => {

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Reset Product Purchase!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    // let purchase_arr = []

                    // let accessable_qty = page_data.product_details.selected_quantity
                    // if (page_data.product_details.required_quantity < page_data.product_details.selected_quantity) {

                    //     swal({
                    //         title: "Warning!",
                    //         icon: "warning",
                    //         text: "Selected Quantity is Greater then Required Quantity",
                    //         type: "warning",
                    //         confirmButtonColor: "#064e3b",
                    //     })

                    //     return
                    // }
                    // if (page_data.product_details.required_quantity > page_data.product_details.selected_quantity) {

                    //     swal({
                    //         title: "Warning!",
                    //         icon: "warning",
                    //         text: "Request must be filled with required quantity !",
                    //         type: "warning",
                    //         confirmButtonColor: "#064e3b",
                    //     })

                    //     return
                    // }

                    // if (page_data.request_details.request_item.active_status == 2 && page_data.request_details.request_item.duty_status == 1) {
                    //     if (page_data.product_details.required_quantity > page_data.product_details.selected_quantity) {

                    //         swal({
                    //             title: "Warning!",
                    //             icon: "warning",
                    //             text: "Selected Quantity is Less then Required Quantity",
                    //             type: "warning",
                    //             confirmButtonColor: "#064e3b",
                    //         })

                    //         return
                    //     }
                    // }

                    // page_data.product_details.purchase_details.forEach(element => {
                    //     if (element.selected == true) {
                    //         purchase_arr.push(element)
                    //     }
                    // });

                    let new_url = "api/set_single_reset_purchase_details_new"

                    page_data.purchase_loading = true

                    const params = {
                        "request_item": page_data.product_details.product_detail,
                        "final_quantity": page_data.request_details.request_item.final_quantity,
                    };

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {

                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                page_data.purchase_loading = false
                                return;
                            }

                            swal({
                                title: "Success!",
                                icon: "success",
                                text: data.message,
                                type: "success",
                                confirmButtonColor: "#064e3b",
                            })

                            // request_products_new(params.request_item_main.id, params.new_quantity)
                            get_request_details()
                            setTimeout(() => {
                              page_data.filter_data.id = id
                              page_data.filter_data.qty = qty
                              request_product_purchase_single()
                            }, timeout);
                            // cash('#purchase-details-modal').modal('hide')

                            page_data.purchase_loading = false
                        })
                        .catch(_error => {

                            page_data.purchase_loading = false

                        });
                }
            });

        }

        const get_purchase_itms = (id,qty) => {
          page_data.filter_data.id = id
          page_data.filter_data.qty = qty

          page_data.filter_data.material_id = "0"
          page_data.filter_data.status = "0"
          request_product_purchase_single()
        }

        // Accept Direct All request
        const accept_request_all = async () => {
            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Accept Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let new_url = "api/accept_request_direct_all_new"

                    page_data.request_loading = true

                    const params = {
                        "request_item": page_data.request_details.request_item,
                        "product_items": page_data.request_details.request_products,
                        "new_quantity": page_data.request_details.new_quantity,
                    };

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {

                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                page_data.request_loading = false
                                return;
                            }

                            swal({
                                title: "Success!",
                                icon: "success",
                                text: data.message,
                                type: "success",
                                confirmButtonColor: "#064e3b",
                            })
                            get_request_details()

                            page_data.request_loading = false
                        })
                        .catch(_error => {

                            page_data.request_loading = false

                        });
                }
            });
        }
        // Reject Request All request
        const reject_direct = async () => {

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Reject Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let new_url = "api/reject_request_direct_all"

                    const params = {
                        "id": window.btoa(page_data.request_details.request_item.id),
                    };

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {

                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                return;
                            }

                            swal({
                                title: "Success!",
                                icon: "success",
                                text: data.message,
                                type: "success",
                                confirmButtonColor: "#064e3b",
                            })

                            get_request_details()

                        })
                        .catch(_error => {

                        });
                }
            });
        }
        // Reject Duty request
        const reject_duty_request = async () => {

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Accept Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let new_url = "api/cancel_duty_request_direct_all_new"

                    const params = {
                        "id": window.btoa(page_data.request_details.request_item.id),
                    };

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {

                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                return;
                            }

                            swal({
                                title: "Success!",
                                icon: "success",
                                text: data.message,
                                type: "success",
                                confirmButtonColor: "#064e3b",
                            })

                            get_request_details()

                        })
                        .catch(_error => {

                        });
                }
            });
        }
        // Sent Duty Request Direct All request
        const send_duty_request = async () => {

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Accept Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let new_url = "api/send_duty_request_direct_all_new"

                    page_data.request_loading = true

                    const params = {
                        "id": window.btoa(page_data.request_details.request_item.id),
                    };

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {

                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                page_data.request_loading = false
                                return;
                            }

                            if (data.items.length > 0) {
                                page_data.duty_assigned_arr = data.items
                                setTimeout(() => {
                                    cash('#duty-request-success-modal').modal('show')

                                }, 100);
                                page_data.request_loading = false
                                get_request_details()
                                return
                            }
                            swal({
                                title: "Success!",
                                icon: "success",
                                text: data.message,
                                type: "success",
                                confirmButtonColor: "#064e3b",
                            })

                            get_request_details()

                            page_data.request_loading = false
                        })
                        .catch(_error => {

                            page_data.request_loading = false

                        });
                }
            });

        }
        const outward_module_request = async () => {
            swal({
                title: "Are you sure?",
                text: "You want to Deliver to the Party!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    // if (page_data.ref_no == "") {
                    //     swal({
                    //         title: "Warning!",
                    //         icon: "warning",
                    //         text: "Please Enter Invoice Number First.",
                    //         type: "warning",
                    //         confirmButtonColor: "#064e3b",
                    //     })
                    //     // swal("Warning!", "Please Enter OOC Number First.", "warning");
                    // } else {

                        let new_url = "api/outward_request_direct_all"

                        page_data.request_loading = true

                        const params = {
                            id: window.btoa(page_data.request_details.request_item.id),
                            ref_no: page_data.ref_no == "" ? "" : window.btoa(page_data.ref_no),
                            party_name: page_data.party_name == "" ? "" : window.btoa(page_data.party_name),
                            out_quantity: page_data.out_quantity == "" ? "" : window.btoa(page_data.out_quantity),
                            date: page_data.date == "" ? "" : window.btoa(page_data.date)
                        };

                        let promise = axios({
                            url: new_url,
                            method: "POST",
                            data: params,
                            baseURL: BASE_URL,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token")
                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            }
                        });
                        return promise
                            .then(result => result.data)
                            .then(data => {

                                if (data.error == "YES") {

                                    swal({
                                        title: "Warning!",
                                        icon: "warning",
                                        text: data.message,
                                        type: "warning",
                                        confirmButtonColor: "#064e3b",
                                    })
                                    page_data.request_loading = false
                                    return;
                                }

                                swal({
                                    title: "Success!",
                                    icon: "success",
                                    text: data.message,
                                    type: "success",
                                    confirmButtonColor: "#064e3b",
                                })

                                get_request_details()

                                cash('#reference-number-modal').modal('hide')

                                page_data.request_loading = false
                            })
                            .catch(_error => {

                                page_data.request_loading = false

                            });
                    // }
                }
            });

        }

        const send_direct_duty_request = (item) => {

          // Send Direct Duty Request
          //screenData.return_progress = true;
          swal({
            title: "Are you sure?",
            text: "You want to Send Request to Stock Department!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#064e3b",
            cancelButtonColor: "#d33",
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
            cancelButtonText: "Cancel",

            allowOutsideClick: () => !$Swal.isLoading()
          }).then(result => {
              if(result.isConfirmed){

                let new_item = [{
                  id: item.id,
                  duty_amount: (item.per_unit_qty > 0 && item.cr_qty_pending > 0) ? $h.myFloat(item.per_unit_qty * ((item.cr_qty_pending ? item.cr_qty_pending : 0) - (item.cr_qty_duty_request ? item.cr_qty_duty_request : 0))) : 0,
                  duty_pay_quantity: item.cr_qty_pending
                }]

                const params = {
                  "purchases": new_item
                };

                let promise = axios({
                  url: "api/set_direct_duty_pay_request",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                  config: { headers: { Accept: "application/json" } }
                });
                return promise
                  .then(result => result.data)
                  .then(data => {

                    if(data.error == "NO") {
                      swal.fire({
                        icon: "success",
                        title: "Success!",
                        text: data.message,
                        showConfirmButton: false,
                        timer: 2000
                      })

                      request_product_purchase_single()
                    } else {
                      swal({
                        title: "Warning!",
                        icon: "warning",
                        text: data.message,
                        type: "warning",
                        confirmButtonColor: "#064e3b",
                      })

                    }
                  })
                  .catch(_error => {

                  });

              }
          });
        }
        const openModal = () => {
            page_data.ref_no = ''
            page_data.party_name = ''
            page_data.out_quantity = ''
            page_data.date = ''
            cash('#reference-number-modal').modal('show')
        }
        onMounted(() => {
            get_request_details()
        })

        const printReportDetails = () => {

        }
        return {
            page_data,
            userDetails,
            printReportDetails,
            request_product_purchase_single,
            update_quantity,
            calculate_update_quantity,
            add_single_product_request_quantity,
            add_single_reset_request_quantity,
            accept_request_all,
            reject_direct,
            reject_duty_request,
            send_duty_request,
            outward_module_request,
            openModal,
            validate_quantity,
            get_purchase_itms,
            send_direct_duty_request
        }
    }
})
</script>

<style scoped>
.tableFixHead {
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    max-height: 700px;
    /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-group .input-group-text-custom {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
