<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">OW Requests</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0" v-if="userDetails.user_type == 3 || userDetails.user_type == 1">
            <a data-toggle="modal" data-target="#product-details-modal" href="javascript:;" class="btn btn-primary shadow-md mr-2">
                New OW Request
            </a>

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="grid grid-cols-12 gap-x-2 mt-5">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="input-quantity" class="form-label">OW NO</label>
                <input id="input-quantity" class="form-control" placeholder="OW NO" autocomplete="off" v-model="outward_filters.request_code" type="text">
            </div>
            <!-- <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="pos-form-2" class="form-label">Select Party</label>
                <TomSelect v-model="outward_filters.party_id" :options="{
                placeholder: 'Select Party'
              }" class="w-full" name="Party" id="pos-form-2">
                    <option value="0">Select Party</option>
                    <option v-for="item in pagedata.party_details" :key="item.id" :value="item.id">{{ item.companey_name}}</option>
                </TomSelect>
            </div> -->
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="pos-form-2" class="form-label">Select Watt</label>
                <TomSelect v-model="outward_filters.watt_id" :options="{
                placeholder: 'Select Watt'
              }" class="w-full" name="Watt" id="pos-form-2">
                    <option value="0">Select Watt</option>
                    <option v-for="item in pagedata.watt_details" :key="item.id" :value="item.id">{{ item.watt}}</option>
                </TomSelect>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="pos-form-2" class="form-label">Module Type</label>
                <TomSelect v-model="outward_filters.pannel_type" :options="{
                placeholder: 'Select Module Type'
              }" class="w-full" name="Module Type" id="pos-form-2">
                    <option value="0">Select Module Type</option>
                    <option value="1">Poly</option>
                    <option value="2">MBB</option>
                </TomSelect>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="pos-form-2" class="form-label">Status</label>
                <TomSelect v-model="outward_filters.status" :options="{
                placeholder: 'Select Status'
              }" class="w-full" name="Status" id="pos-form-2">
                    <option value="0">Select Status</option>
                    <option value="1">Pending</option>
                    <option value="2">Accepted</option>
                    <option value="6">Duty Requested</option>
                    <option value="3">Cancelled</option>
                    <option value="4">Duty Paid</option>
                    <option value="5">Delivered</option>
                </TomSelect>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1">
                <div class="mt-5">
                    <button @click="resetStockFilters" class="btn btn-secondary w-full mt-2">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1">
                <div class="mt-5">
                    <button @click="initTabulator()" class="btn btn-primary w-full mt-2">
                        Generate
                    </button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

    <!--- BEGIN: Consumption Products Model --->
    <div id="product-details-modal-back" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Request Products</h2>
                        <div>
                            <a data-dismiss="modal" href="javascript:;">
                                <XIcon class="w-8 h-8 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body py-5 px-5">

                    <div class="grid grid-cols-12 gap-x-2">
                        <div class="col-span-12 md:col-span-6 xl:col-span-5 py-1">
                            <h2 class="text-lg font-medium mt-2 mr-auto"><b>OW NO</b> : {{ pagedata.request_details.request_item.request_code }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1">
                            <h2 class="text-lg font-medium mt-2 mr-auto"><b>Watt</b> : {{ pagedata.request_details.request_item.watt }}</h2>
                        </div>
                        <!-- <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1">
                            <h2 class="text-lg font-medium mt-2 mr-auto"><b>Party</b> : {{ pagedata.request_details.request_item.companey_name }}</h2>
                        </div> -->
                        <div class="col-span-12 md:col-span-6 xl:col-span-5 py-1">
                            <h2 class="text-lg font-medium mt-2 mr-auto"><b>Request Date</b> : {{ pagedata.request_details.request_item.issue_date }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1">
                            <h2 class="text-lg font-medium mt-2 mr-auto"><b>Type</b> : {{ pagedata.request_details.request_item.product_type == 1 ? 'Poly' : (pagedata.request_details.request_item.product_type == 2 ? 'MBB' : 'Both') }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1">
                            <h2 class="text-lg font-medium mt-2 mr-auto"><b>Request Module</b> : {{ pagedata.request_details.request_item.quantity }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-5 py-1">
                            <h2 class="text-lg font-medium mt-2 mr-auto"><b>Accept Date</b> : {{ pagedata.request_details.request_item.accepted_at ? pagedata.request_details.request_item.accepted_at : '-' }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1" v-if="pagedata.request_details.request_item.active_status">
                            <h2 class="text-lg font-medium mt-2 mr-auto"><b>Status</b> :
                                <span class="text-theme-1" v-if="pagedata.request_details.request_item.active_status == 1">Pending</span>
                                <span class="text-theme-3" v-if="pagedata.request_details.request_item.active_status == 2">Duty Pending</span>
                                <span class="text-theme-6" v-if="pagedata.request_details.request_item.active_status == 3">Rejected</span>
                                <span class="text-theme-9" v-if="pagedata.request_details.request_item.active_status == 4">Accepted</span>
                                <span class="text-theme-9" v-if="pagedata.request_details.request_item.active_status == 5">Delivered</span>
                            </h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1">
                            <h2 class="text-lg font-medium mt-2 mr-auto"><b>Est Module</b> : {{ pagedata.request_details.request_item.est_consumption }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-5 py-1">
                            <h2 class="text-lg font-medium mt-2 mr-auto"><b>Stock Date</b> : {{ pagedata.request_details.request_item.outward_date ? pagedata.request_details.request_item.outward_date : '-' }}</h2>
                        </div>
                        <div class="xl:col-span-7"></div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-5">
                            <div class="flex items-center">

                                <h2 class="text-lg font-medium mt-2 mr-auto flex items-center"><b>Final Module</b> :
                                    <input type="text" class="form-control w-36 ml-1 p-1" v-model="pagedata.request_details.request_item.final_quantity" placeholder="Final Quantity"></h2>
                                <button class="btn btn-primary mt-1" :disabled="pagedata.request_details.request_item.active_status != 1" @click="request_products_new(pagedata.request_details.request_item.id,pagedata.request_details.request_item.final_quantity)">
                                    Set Qty
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="border mt-5 p-0 shadow-xl">

                        <div class="overflow-x-auto tableFixHead">
                            <table class="w-full text-center">
                                <thead>
                                    <tr class="bg-theme-14">
                                        <th class="border-b py-2 px-2 dark:border-dark-5">#</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Material</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Specification</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Type</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Per Module <br> Qantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Required Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Current <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty Paid Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty Pending Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Ramaining Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Purchase Details</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item, index) in pagedata.request_details.request_products" :key="index">
                                        <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                        <td class="border-b py-2 px-2">{{ item.product_name }}</td>
                                        <td class="border-b py-2 px-2">{{ item.description }}</td>
                                        <td class="border-b py-2 px-2">{{ item.product_type == 1 ? 'Poly' : (item.product_type == 2 ? 'MBB' : 'Both') }}</td>
                                        <td class="border-b py-2 px-2">{{ item.quantity_per_panel ? $h.myFloat(item.quantity_per_panel) + (item.uqc_name ? item.uqc_name : '') : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.quantity ? $h.myFloat(item.quantity) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.quantity_cr ? $h.myFloat(item.quantity_cr) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.quantity_paid ? $h.myFloat(item.quantity_paid) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.quantity_pending ? $h.myFloat(item.quantity_pending) : '-' }}</td>
                                        <td class="border-b py-2 px-2" :class="{'text-primary-3' : item.quantity_diff < 0,'text-primary-4' : item.quantity_diff > 0}">{{ item.quantity_diff > 0 ? '+' : '' }} {{ $h.myFloat(item.quantity_diff) }}</td>
                                        <td class="border-b py-2 px-2">
                                            <button class="btn btn-primary px-2 py-1" :disabled="item.active_status == 3" @click="request_product_purchase_single(item.id,item.quantity)">Purchases</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="pagedata.request_details.request_products.length == 0" class="text-md font-medium mr-auto text-center my-8">
                            Records Not Found
                        </div>
                    </div>

                </div>
                <!-- BEGIN: Modal Footer -->
                <div class="modal-footer text-right mr-2">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary mr-1">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-danger mr-1" @click="rejectDirectAll" v-if="(pagedata.request_details.request_item.active_status == 2 && pagedata.request_details.request_item.duty_status == 1)">
                        Reject Request
                    </button>
                    <button type="button" class="btn btn-danger mr-1" @click="rejectDirectAll" v-if="pagedata.request_details.request_item.active_status == 1">
                        Reject Request
                    </button>
                    <button type="button" class="btn btn-danger mr-1" @click="rejectDutyDirectAll" v-if="(pagedata.request_details.request_item.active_status == 2 && pagedata.request_details.request_item.duty_status == 2) || pagedata.request_details.request_item.active_status == 4">
                        Reject Duty
                    </button>
                    <button type="button" class="btn btn-primary mr-1" :disabled="pagedata.request_loading" @click="acceptDirectAll" v-if="pagedata.request_details.request_item.active_status == 1">
                        Accept Request
                        <LoadingIcon v-if="pagedata.request_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                    <button type="button" class="btn btn-primary" :disabled="pagedata.request_loading" @click="sendDutyRequest" v-if="pagedata.request_details.request_item.active_status == 2 && pagedata.request_details.request_item.duty_status == 1">
                        Duty Request
                        <LoadingIcon v-if="pagedata.request_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                </div>
                <!-- END: Modal Footer -->
            </div>
        </div>
    </div>
    <!--- END: Consumption Products Model --->

    <!--- BEGIN: Product Purchases Model --->
    <div id="purchase-details-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Product Purchase Details</h2>
                        <div>
                            <a data-dismiss="modal" href="javascript:;">
                                <XIcon class="w-8 h-8 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body py-10 px-5">
                    <div class="grid grid-cols-12 gap-x-2">
                        <div class="col-span-12 md:col-span-6 xl:col-span-6 py-1">
                            <h2 class="text-lg mt-2 mr-auto"><b>Material</b> : {{ pagedata.product_details.product_detail.product_name }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-6 py-1">
                            <h2 class="text-lg mt-2 mr-auto"><b>Specification</b> : {{ pagedata.product_details.product_detail.description }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-6 py-1">
                            <h2 class="text-lg mt-2 mr-auto"><b>Type</b> : {{ pagedata.product_details.product_detail.product_type == 1 ? 'Poly' : (pagedata.product_details.product_detail.product_type == 2 ? 'MBB' : 'Both') }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-3 py-1" v-if="pagedata.product_details.product_detail.active_status">
                            <h2 class="text-lg mt-2 mr-auto"><b>Status</b> :
                                <span class="text-theme-1" v-if="pagedata.request_details.request_item.active_status == 1">Pending</span>
                                <span class="text-theme-3" v-if="pagedata.request_details.request_item.active_status == 2">Duty Pending</span>
                                <span class="text-theme-6" v-if="pagedata.request_details.request_item.active_status == 3">Rejected</span>
                                <span class="text-theme-9" v-if="pagedata.request_details.request_item.active_status == 4">Accepted</span>
                                <span class="text-theme-9" v-if="pagedata.request_details.request_item.active_status == 5">Delivered</span>
                            </h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-6 py-1">
                            <h2 class="text-lg mt-2 mr-auto"><b>Request Quantity</b> : {{ pagedata.product_details.required_quantity }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-6 xl:col-span-6 py-1">
                            <h2 class="text-lg mt-2 mr-auto"><b>Selected Quantity</b> : {{ pagedata.product_details.selected_quantity }}</h2>
                        </div>
                    </div>

                    <div class="border mt-5 p-0 shadow-xl">

                        <div class="overflow-x-auto tableFixHead">
                            <table class="w-full text-center">
                                <thead class="">
                                    <tr class="bg-theme-14">
                                        <th class="border-b py-2 px-2 dark:border-dark-5">#</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Purchase</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Required <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Cr Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty Paid <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">OOC Pending <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty Pending <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty Request <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Action</th>
                                    </tr>
                                    <tr class="bg-gray-200" v-if="pagedata.product_details.purchase_details.length != 0">
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2">{{ pagedata.product_details.total.cr_new_qty }}</td>
                                        <td class="border-b py-2 px-2">{{ pagedata.product_details.total.cr_qty }}</td>
                                        <td class="border-b py-2 px-2">{{ pagedata.product_details.total.cr_qty_paid }}</td>
                                        <td class="border-b py-2 px-2">{{ pagedata.product_details.total.cr_qty_pending }}</td>
                                        <td class="border-b py-2 px-2">{{ pagedata.product_details.total.cr_qty_duty_request }}</td>
                                        <td class="border-b py-2 px-2">{{ pagedata.product_details.total.cr_qty_ooc_pending }}</td>
                                        <td class="border-b py-2 px-2"></td>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0 && item.theme == ''}" v-for="(item, index) in pagedata.product_details.purchase_details" :key="index">
                                        <td class="border-b py-2 px-2">
                                            <div class="flex items-center">
                                                <input type="checkbox" class="p-2 form-check" v-model="item.selected" @change="update_quantity(item)" :disabled="pagedata.product_details.limit_touch == true && item.selected == false">
                                                <span class="ml-2"> {{ index + 1 }}</span>
                                            </div>
                                        </td>
                                        <td class="border-b py-2 px-2">{{ item.purchase_code }}</td>
                                        <td class="border-b py-2 px-2"><input type="text" name="required_quantity" id="required_quantity" autocomplete="off" @change="calculate_update_quantity" :disabled="item.selected == false" class="form-control w-32 text-center" v-model="item.cr_new_qty" placeholder="Required Qty"></td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty ? item.cr_qty : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty_paid ? item.cr_qty_paid : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty_pending ? item.cr_qty_pending : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty_duty_request ? item.cr_qty_duty_request : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty_ooc_pending ? item.cr_qty_ooc_pending : '-' }}</td>
                                        <td class="border-b py-2 px-2"><button class="btn btn-primary" @click="getProductDetails(item.main_purchase_id,item.purchase_type)">
                                                View
                                            </button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div v-if="pagedata.product_purchase_detail.purchases.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
              </div> -->
                    </div>
                </div>
                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary mr-1">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" :disabled="pagedata.purchase_loading" @click="add_single_product_request_quantity">
                        Accept Purchase
                        <LoadingIcon v-if="pagedata.purchase_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!--- END: Product Purchases Model --->

    <!--- BEGIN: Duty Request Success Modal --->
    <div id="duty-request-success-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-body py-10 px-5">
                    <div class="p-5 text-center">
                        <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
                        <div class="text-3xl mt-5">Duty Request Sent Successfully!</div>
                    </div>

                    <h2 class="text-lg font-medium mr-auto my-3">{{ pagedata.message }}</h2>
                    <div class="border mt-5 p-0 shadow-xl">

                        <div class="overflow-x-auto tableFixHead">
                            <table class="w-full text-center">
                                <thead>
                                    <tr class="bg-theme-14">
                                        <th class="border-b py-2 px-2 dark:border-dark-5">#</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Product</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Material</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Type</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty <br> Paid <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty <br> Pending <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Direct Duty <br> Request <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Custom Duty <br> Request <br> Quantity</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0 && item.theme == ''}" v-for="(item, index) in pagedata.duty_assigned_arr" :key="index">
                                        <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                        <td class="border-b py-2 px-2">{{ item.product_name }}</td>
                                        <td class="border-b py-2 px-2">{{ item.description }}</td>
                                        <td class="border-b py-2 px-2">{{ item.product_type }}</td>
                                        <td class="border-b py-2 px-2">{{ item.quantity ? item.quantity : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.duty_paid ? item.duty_paid : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.duty_pending ? item.duty_pending : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.direct_duty_request ? item.direct_duty_request : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.custom_duty_request ? item.custom_duty_request : '-' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div v-if="pagedata.product_purchase_detail.purchases.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
              </div> -->
                    </div>
                    <div class="px-5 py-8 text-right">
                        <button type="button" data-dismiss="modal" class="btn btn-primary w-24">
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--- END: Product Purchases Model --->
    <!--- BEGIN: Purchase Details Model --->
    <div id="dta_purchase-details-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Purchase No : {{ pagedata.purchase_item.dta_code }}</h2>
                        <div>
                            <a data-dismiss="modal" href="javascript:;">
                                <XIcon class="w-8 h-8 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body p-10">

                    <table class="w-full border mt-3">

                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Gst Invoice No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Gst Invoice Date</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Company Name</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Import Station</th>
                        </tr>
                        <tr class="">
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.gst_invoice_no }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.gst_invoice_date }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.companey_name }}</th>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.station_name }}</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">E Way Bill No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">E Way Bill Date</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">PO NO</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">PO Date</th>
                        </tr>
                        <tr class="">
                            <th class="py-2 border-b lg:text-m-r" colspan="2">{{ pagedata.purchase_item.eway_bill_no ? pagedata.purchase_item.eway_bill_no : '-' }}</th>
                            <th class="py-2 border-b lg:text-m-r" colspan="2">{{ pagedata.purchase_item.eway_bill_date ? pagedata.purchase_item.eway_bill_date : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.po_no ? pagedata.purchase_item.po_no : '-' }}</th>
                            <th class=" py-2 border-b lg:text-m-r" colspan="2">{{ pagedata.purchase_item.po_date ? pagedata.purchase_item.po_date : '-' }}</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Insurance No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Insurance Amount</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Freigth Type</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Freigth</th>
                        </tr>
                        <tr class="">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{ pagedata.purchase_item.insurance_no ? pagedata.purchase_item.insurance_no : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{ pagedata.purchase_item.insurance ? pagedata.purchase_item.insurance + ' INR' : '-' }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                                <span v-if="pagedata.purchase_item.freigth_type == 2">Ex-W</span>
                                <span v-if="pagedata.purchase_item.freigth_type == 3">For</span>
                                <span v-if="pagedata.purchase_item.freigth_type == 4">Godown Delivery</span>
                                <span v-if="pagedata.purchase_item.freigth_type == 1">Door Delivery</span>
                            </th>
                            <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r">{{ pagedata.purchase_item.freigth ? pagedata.purchase_item.freigth + ' INR' : '-' }}</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Warehouse Receipt Time</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Inward Date</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Inward Amount</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Accessable Value</th>
                        </tr>
                        <tr class="">
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.warehouse_receipt_time ? pagedata.purchase_item.warehouse_receipt_time : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{ pagedata.purchase_item.inward_date }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2" rowspan="5">{{ pagedata.purchase_item.inward_value }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2" rowspan="5">{{ pagedata.purchase_item.accessable_value }}</th>
                        </tr>
                    </table>

                    <h2 class="font-medium text-base mr-auto mt-5">Purchase Products</h2>
                    <table class="w-full border mt-3">
                        <thead>
                            <tr class="bg-gray-200 dark:bg-dark-1 border">
                                <th>Product Name</th>
                                <th>Material Specification</th>
                                <th>Product Type</th>
                                <th>Quantity</th>
                                <th>Per Unit Rate</th>
                                <th>Total Amount</th>
                                <th>GST</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in pagedata.purchase_item.purchase_details" class="text-center border" :key="index">
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.product_name }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.description }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.product_type == 3 ? 'Both' : (item.product_type == 1 ? 'Poly' : 'MBB') }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.quantity }} {{ item.uqc_name }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.value_per_unit }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.accessable_value }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.gst_value ? item.gst_value : '-' }} {{ item.gst && item.gst_value ? ' ( '+ item.gst + '% ) ' : '' }}</td>
                            </tr>
                        </tbody>

                    </table>

                    <h2 class="font-medium text-base mr-auto mt-5">Purchase Duties</h2>
                    <table class="w-full border mt-3" v-for="(item, index) in pagedata.purchase_item.purchase_details" :key="index">
                        <tr class="bg-gray-200 dark:bg-dark-1">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="7">{{ index + 1 }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Product Name</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Material Specification</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.product_name }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.description }}</th>
                        </tr>
                        <tr class="bg-gray-100 dark:bg-dark-1">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Quantity</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">UQC</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Product Amount</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Insurance</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Freigth</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">CIF Amount</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.quantity }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.uqc_name }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.accessable_value ? parseFloat(item.accessable_value).toFixed(2) : '-' }}</th>
                            <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.conversation_value && item.value_per_unit) ? (item.value_per_unit * item.conversation_value) : '' }}</th> -->
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.insurance ? parseFloat(item.insurance).toFixed(2) : '-' }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.freigth ? parseFloat(item.freigth).toFixed(2) : '-' }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.total_access_weight ? parseFloat(item.total_access_weight).toFixed(2) : '-' }}</th>
                        </tr>
                        <tr class="bg-gray-100 dark:bg-dark-1">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r"></th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">GST</th>
                            <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Comp Cess</th> -->
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Duty Total</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Total Amount With Duty</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">%</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.gst }}</th>
                            <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.comp_cess }}</th> -->
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="2" colspan="2">{{ item.duty_amount }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2" rowspan="2">{{ item.total_value }}</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Amount</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.gst_value }}</th>
                            <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.comp_cess_value }}</th> -->
                        </tr>
                    </table>

                </div>
            </div>
        </div>
    </div>
    <!--- END: Purchase Details Model --->
    <!--- BEGIN: Purchase Details Model --->
    <div id="import_purchase-details-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Purchase No : {{ pagedata.purchase_item.import_code }}</h2>
                        <div>
                            <a data-dismiss="modal" href="javascript:;">
                                <XIcon class="w-8 h-8 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body p-10">

                    <table class="w-full border mt-3">

                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Bill No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Bill Date</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Company Name</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Import Station</th>
                        </tr>
                        <tr class="">
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.bill_no ? pagedata.purchase_item.bill_no : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.bill_date ? pagedata.purchase_item.bill_date : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.companey_name ? pagedata.purchase_item.companey_name : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.station_name ? pagedata.purchase_item.station_name : '-' }}</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Invoice No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Invoice Date</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">PO No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">PO Date</th>
                        </tr>
                        <tr class="">
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.invoice_no ? pagedata.purchase_item.invoice_no : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.invoice_date ? pagedata.purchase_item.invoice_date : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.po_no ? pagedata.purchase_item.po_no : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{ pagedata.purchase_item.po_date ? pagedata.purchase_item.po_date : '-' }}</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Freigth Type</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Freigth</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Freigth Conversation</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Freigth Amount</th>
                        </tr>
                        <tr class="">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                                <span v-if="pagedata.purchase_item.freigth_type == 2">Ex-W</span>
                                <span v-if="pagedata.purchase_item.freigth_type == 3">For</span>
                                <span v-if="pagedata.purchase_item.freigth_type == 4">Godown Delivery</span>
                                <span v-if="pagedata.purchase_item.freigth_type == 1">Door Delivery</span>
                            </th>
                            <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r">{{ pagedata.purchase_item.freigth }} {{ pagedata.purchase_item.freigth_currency_name }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{ pagedata.purchase_item.freigth_con_value ? pagedata.purchase_item.freigth_con_value : '-' }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{ pagedata.purchase_item.freigth_value ? pagedata.purchase_item.freigth_value : '-' }}</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Bond Details</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Transport No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Insurance No</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Insurance Amount</th>
                        </tr>
                        <tr class="">
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.bond_detail ? pagedata.purchase_item.bond_detail : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.transport_no ? pagedata.purchase_item.transport_no : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.insurance_no ? pagedata.purchase_item.insurance_no : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.insurance ? pagedata.purchase_item.insurance : '-' }} INR</th>
                        </tr>
                        <tr class="">
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">One Time Lock</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Warehouse Receipt Time</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Inward Amount</th>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md border-r" colspan="2">Accessable Value</th>
                        </tr>
                        <tr>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.one_time_lock ? pagedata.purchase_item.one_time_lock : '-' }}</th>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.warehouse_receipt_time ? pagedata.purchase_item.warehouse_receipt_time : '-' }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2" rowspan="2">{{ pagedata.purchase_item.inward_value }}</th>
                            <th class="py-2 border-b lg:text-md border-r" colspan="2" rowspan="2">{{ pagedata.purchase_item.accessable_value }}</th>
                        </tr>
                        <tr>
                            <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Inward Date</th>
                            <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.inward_date }}</th>
                        </tr>
                    </table>

                    <h2 class="font-medium text-base mr-auto mt-5">Purchase Products</h2>
                    <table class="w-full border mt-3">
                        <thead>
                            <tr class="bg-gray-200 dark:bg-dark-1 border">
                                <th>Product Name</th>
                                <th>Material Specification</th>
                                <th>Product Type</th>
                                <th>Quantity</th>
                                <th>Per Unit Rate</th>
                                <th>Conversation Value</th>
                                <th>Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in pagedata.purchase_item.purchase_details" class="text-center" :key="index">
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.product_name }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.description }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.product_type == 3 ? 'Both' : (item.product_type == 1 ? 'Poly' : 'MBB') }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.quantity }} {{ item.uqc_name }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.value_per_unit }} {{ item.value_currency_name }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.value_con }}</td>
                                <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.accessable_value }}</td>
                            </tr>
                        </tbody>

                    </table>

                    <h2 class="font-medium text-base mr-auto mt-5">Purchase Duties</h2>
                    <table class="w-full border mt-3" v-for="(item, index) in pagedata.purchase_item.purchase_details" :key="index">
                        <tr class="bg-gray-200 dark:bg-dark-1">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="5">{{ index + 1 }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Product Name</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Material Specification</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.product_name }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.description }}</th>
                        </tr>
                        <tr class="bg-gray-100 dark:bg-dark-1">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Quantity</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">UQC</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Product Amount</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Insurance</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Freigth</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">CIF Amount</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.quantity }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.uqc_name }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.accessable_value ? parseFloat(item.accessable_value).toFixed(2) : '-' }}</th>
                            <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.conversation_value && item.value_per_unit) ? (item.value_per_unit * item.conversation_value) : '' }}</th> -->
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.insurance ? parseFloat(item.insurance).toFixed(2) : '-' }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.freigth ? parseFloat(item.freigth).toFixed(2) : '-' }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.total_access_weight ? parseFloat(item.total_access_weight).toFixed(2) : '-' }}</th>
                        </tr>
                        <tr class="bg-gray-100 dark:bg-dark-1">
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">BCD</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">IGST</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Comp Cess</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Duty Total</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Total Amount With Duty</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">%</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.bcd }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.igst }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.comp_cess }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="2">{{ item.duty_amount }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2" rowspan="2">{{ item.total_value }}</th>
                        </tr>
                        <tr>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Amount</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.bcd_value }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.igst_value }}</th>
                            <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.comp_cess_value }}</th>
                        </tr>
                    </table>

                </div>
            </div>
        </div>
    </div>
    <!--- END: Purchase Details Model --->
    <!-- BEGIN: Modal Content -->
    <div id="reference-number-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- BEGIN: Modal Header -->
                <div class="modal-header">
                    <h2 class="font-medium text-base mr-auto">
                        Confirm Invoice Number
                    </h2>
                </div>
                <!-- END: Modal Header -->
                <!-- BEGIN: Modal Body -->
                <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                    <div class="col-span-12">
                        <div class="text-3xl mt-5 text-center">Are you sure?</div>
                        <div class="text-gray-600 mt-2 text-center">
                            Please note that this will automatically <br>
                            update Invoice Number, <br>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Invoice Number</label>
                        <input id="ref_no" type="text" v-model="pagedata.ref_no" class="form-control" autocomplete="off" placeholder="Invoice No" />
                    </div>
                    <div class="col-span-12">
                        <div class="text-black mt-2"><b>Please confirm the Invoice No !</b></div>
                    </div>
                </div>
                <!-- END: Modal Body -->
                <!-- BEGIN: Modal Footer -->
                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-28 mr-1">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" :disabled="pagedata.request_loading" @click="outward_module_request">
                        Submit
                        <LoadingIcon v-if="pagedata.request_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                </div>
                <!-- END: Modal Footer -->
            </div>
        </div>
    </div>
    <!-- END: Modal Content -->
    <!--- BEGIN: Consumption Products Model --->
    <div id="product-details-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Request Form</h2>
                        <div>
                            <a data-dismiss="modal" href="javascript:;">
                                <XIcon class="w-8 h-8 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body p-0">

                  <div class="sm:grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-2 border-b p-5" v-for="(plan,index) in form_data" :key="index">
                    <div>
                      <label for="regular-form-1" class="form-label">Watt Size</label>
                      <div>

                        <TomSelect v-model="plan.watt_id" :options="{
                        placeholder: 'Select Watt'
                      }" class="w-full" name="Watt" id="pos-form-2" @change="get_product_consumption_details(plan)">
                                <option value="0">Select Watt</option>
                                <option v-for="item in pagedata.watt_details" :key="item.id" :value="item.id">{{ item.watt}}</option>
                            </TomSelect>
                      </div>
                    </div>
                    <div>
                      <label for="regular-form-1" class="form-label">Panel type</label>
                      <div>

                        <TomSelect v-model="plan.pannel_type" :options="{
                        placeholder: 'Select Watt'
                      }" class="w-full" name="Watt" id="pos-form-2" @change="get_product_consumption_details(plan)">
                                <option value="0">Select Type</option>
                                <option value="1">Poly</option>
                                <option value="2">MBB</option>
                            </TomSelect>
                      </div>
                    </div>
                    <div>
                          <label for="regular-form-1" class="form-label">Quantity</label>
                          <div>
                            <input v-model="plan.quantity" type="number" :id="'quantity_' + index" @input="calculate_quantity_items(plan)" class="form-control" placeholder="Quantity">
                          </div>
                    </div>
                    <div>
                        <div>

                      </div>
                    </div>
                  </div>

                  <div class="intro-y col-span-12 p-5">
                      <div class="intro-y">
                          <h2 class="font-medium text-lg mr-auto">Estimate Consumption</h2>
                          <div class="my-6 shadow" v-for="(main_item, index) in form_data" :key="index">

                              <table class="table w-full text-center border" v-if="main_item.product_details.length > 0">
                                  <thead>
                                      <tr class="bg-theme-14">
                                          <th class="border-b dark:border-dark-5 whitespace-nowrap">{{ index + 1 }}</th>
                                          <th class="border-b dark:border-dark-5 whitespace-nowrap" colspan="2"><b>Watt : </b>{{ main_item.watt }}</th>
                                          <th class="border-b dark:border-dark-5 whitespace-nowrap" colspan="2"><b>Quantity : </b>{{ main_item.quantity }}</th>
                                          <!-- <th class="border-b dark:border-dark-5 whitespace-nowrap"></th> -->
                                      </tr>
                                      <tr class="bg-theme-14">
                                          <th class="border-b dark:border-dark-5 whitespace-nowrap"></th>
                                          <th class="border-b dark:border-dark-5 whitespace-nowrap">Material</th>
                                          <!-- <th class="border-b dark:border-dark-5 whitespace-nowrap">Specification</th> -->
                                          <th class="border-b dark:border-dark-5 whitespace-nowrap">Type</th>
                                          <th class="border-b dark:border-dark-5 whitespace-nowrap">Quantity <br> Per Module</th>
                                          <th class="border-b dark:border-dark-5 whitespace-nowrap">Quantity</th>
                                      </tr>
                                  </thead>
                                  <tbody class="text-center" v-if="main_item.product_details.length > 0">
                                      <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (inx % 2) == 0}" v-for="(item, inx) in main_item.product_details" :key="inx">
                                          <td class="border-b">{{ inx + 1 }}</td>
                                          <td class="border-b">{{ item.product_name }}</td>
                                          <!-- <td class="border-b">{{ item.description }}</td> -->
                                          <td class="border-b">{{ item.product_type == 1 ? 'Poly' : (item.product_type == 2 ? 'MBB' : 'Both') }}</td>
                                          <td class="border-b">{{ item.quantity }} {{ item.uqc_name }}</td>
                                          <td class="border-b">{{ item.request_quantity }} {{ item.request_quantity ? item.uqc_name : '-' }}</td>
                                      </tr>
                                  </tbody>
                              </table>

                          </div>
                      </div>
                  </div>

                </div>
                <div class="modal-footer p-5">
                  <div class="flex items-center justify-between">
                              <div>
                                  <!-- <button type="button" @click="add_new_request_field" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">Add Product</button> -->
                              </div>
                              <div>

                                  <button type="button" @click="$router.go(-1)" class="btn btn-outline-secondary w-32 mr-1">
                                      Cancel
                                  </button>
                                  <button type="button" class="btn btn-primary w-32" :disabled="pagedata.submit" @click="send_request_to_stock_department">
                                      Save Details
                                      <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                                  </button>
                              </div>
                          </div>
                </div>
            </div>
        </div>
    </div>
    <!--- END: Consumption Products Model --->

</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    onMounted,
    inject,
    computed,
    watch
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import axios from 'axios'
import VueRouter from 'vue-router'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constant/config.js'
import cash from 'cash-dom'
import moment from 'moment'
import {
    helper as $h
} from '@/utils/helper'
import router from '@/router'
import store from '@/store'

export default defineComponent({
    setup() {
        const swal = inject('$swal')
        const tableRef = ref()
        const tabulator = ref()
        const userDetails = computed(() => store.state.user.currentUser)
        const pagedata = reactive({
            watt_details: [],
            party_details: [],
            purchase_item: [],
            request_loading: false,
            purchase_loading: false,
            request_details: {
                request_item: [],
                request_products: [],
                new_quantity: ''
            },
            product_details: {
                product_detail: [],
                purchase_details: [],
                total: [],
                selected_quantity: 0,
                required_quantity: 0,
                limit_touch: false,
            },
            duty_assigned_arr: [],
            message: '',
            ref_no: '',
            deliver_id: ''
        })

        const form_data = ref([{
            id: 1,
            update_id: '',
            party_id: '0',
            party_name: '',
            watt_id: '0',
            watt: '',
            pannel_type: '0',
            quantity: '',
            new_quantity: '',
            product_details: []
        }])

        const outward_filters = reactive({
            party_id: "0",
            watt_id: "0",
            pannel_type: "0",
            status: "0",
            request_code: "",
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date: moment().format('YYYY-MM-DD'),
            page_size: '1'
        })
        const print_filters = reactive({
            party_id: "0",
            watt_id: "0",
            pannel_type: "0",
            status: "0",
            request_code: "",
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date: moment().format('YYYY-MM-DD')
        })

        const initTabulator = () => {

            print_filters.party_id = outward_filters.party_id
            print_filters.watt_id = outward_filters.watt_id
            print_filters.pannel_type = outward_filters.pannel_type
            print_filters.status = outward_filters.status
            print_filters.request_code = outward_filters.request_code
            print_filters.s_date = outward_filters.s_date
            print_filters.e_date = outward_filters.e_date

            if (userDetails.value.user_type == 3) {

                tabulator.value = new Tabulator(tableRef.value, {
                    ajaxURL: BASE_URL + 'api/get_outward_request_list',
                    ajaxConfig: {
                        method: "GET", //set request type to Position
                        headers: {
                            "Content-type": 'application/json; charset=utf-8', //set specific content type
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        },
                    },
                    ajaxParams: {
                        "party_id": print_filters.party_id == "0" ? "" : window.btoa(print_filters.party_id),
                        "watt_id": print_filters.watt_id == "0" ? "" : window.btoa(print_filters.watt_id),
                        "pannel_type": print_filters.pannel_type == "0" ? "" : window.btoa(print_filters.pannel_type),
                        "status": print_filters.status == "0" ? "" : window.btoa(print_filters.status),
                        "request_code": print_filters.request_code == "" ? "" : window.btoa(print_filters.request_code),
                        "s_date": "",
                        // "s_date": print_filters.s_date == "" ? "" : window.btoa(print_filters.s_date),
                        "e_date": "",
                        // "e_date": print_filters.e_date == "" ? "" : window.btoa(print_filters.e_date),
                    },
                    pagination: 'remote',
                    paginationSize: 20,
                    // renderHorizontal:"virtual",
                    paginationSizeSelector: [20, 30, 40, 60],
                    ajaxFiltering: true,
                    ajaxSorting: true,
                    headerSort: false,
                    printAsHtml: true,
                    printStyled: true,
                    layout: 'fitColumns',
                    responsiveLayout: 'collapse',
                    placeholder: 'No matching records found',
                    columns: [

                        // For HTML table
                        {
                            title: 'OW NO',
                            minWidth: 150,
                            responsive: 0,
                            field: 'request_code',
                            hozAlign: 'left',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter: "textarea",
                            cellClick: function (e, cell) {
                                router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                            }
                        },
                        // {
                        //     title: 'Party',
                        //     minWidth: 100,
                        //     responsive: 0,
                        //     field: 'companey_name',
                        //     hozAlign: 'left',
                        //     vertAlign: 'left',
                        //     print: false,
                        //     download: false,
                        //     formatter: "textarea",
                        //     cellClick: function (e, cell) {
                        //         router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                        //     }
                        // },
                        {
                            title: 'Watt',
                            minWidth: 150,
                            responsive: 0,
                            field: 'watt',
                            hozAlign: 'left',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter: "textarea",
                            cellClick: function (e, cell) {
                                router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                            }
                        },
                        {
                            title: 'Type',
                            minWidth: 100,
                            responsive: 0,
                            field: 'product_type',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return cell.getData().product_type == 1 ? "Poly" : (cell.getData().product_type == 2 ? "MBB" : 'Both')
                            },
                            cellClick: function (e, cell) {
                                router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                            }
                        },
                        {
                            title: 'Request <br> Modules',
                            minWidth: 100,
                            responsive: 0,
                            field: 'quantity',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return cell.getData().quantity
                            },
                            cellClick: function (e, cell) {
                                router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                            }
                        },
                        {
                            title: 'Final <br> Modules',
                            minWidth: 100,
                            responsive: 0,
                            field: 'quantity',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return cell.getData().final_quantity ? cell.getData().final_quantity : (cell.getData().active_status != 1 ? cell.getData().quantity : '-')
                            },
                            cellClick: function (e, cell) {
                                router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                            }
                        },
                        {
                            title: 'Request Date',
                            minWidth: 150,
                            responsive: 0,
                            field: 'issue_date',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter: "textarea",
                            cellClick: function (e, cell) {
                                router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                            }
                        },
                        {
                            title: 'Status',
                            minWidth: 100,
                            field: 'active_status',
                            hozAlign: 'left',
                            vertAlign: 'left',
                            print: false,
                            download: false,
                            formatter(cell) {
                                if (cell.getData().active_status == 1) {

                                    return '<div class="flex items-center lg:justify-center text-theme-1">Pending</div>'

                                } else if (cell.getData().active_status == 2 && cell.getData().duty_status == 1) {

                                    return '<div class="flex items-center lg:justify-center text-theme-3">Accepted</div>'

                                } else if (cell.getData().active_status == 2 && cell.getData().duty_status == 2) {

                                    return '<div class="flex items-center lg:justify-center text-theme-3">Duty Requested</div>'

                                } else if (cell.getData().active_status == 3) {

                                    return '<div class="flex items-center lg:justify-center text-theme-6">Cancelled</div>'

                                } else if (cell.getData().active_status == 4) {

                                    return '<div class="flex items-center lg:justify-center text-theme-9">Duty Paid</div>'
                                } else if (cell.getData().active_status == 5) {

                                    return '<div class="flex items-center lg:justify-center text-theme-9">Delivered</div>'
                                }
                            }
                        },
                        {
                            title: '',
                            field: 'actions',
                            responsive: 1,
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                if (userDetails.value.user_type == 3 && cell.getData().active_status == 4) {

                                    const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-primary py-1">
                      <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Deliver
                    </button>

                  </div>`)
                                    cash(a).on('click', function () {
                                        openModal(cell.getData().id)
                                    })

                                    return a[0]
                                }
                            }
                        },

                    ],
                    renderComplete() {
                        feather.replace({
                            'stroke-width': 1.5
                        })
                    }
                })
            } else {

                tabulator.value = new Tabulator(tableRef.value, {
                    ajaxURL: BASE_URL + 'api/get_outward_request_list',
                    ajaxConfig: {
                        method: "GET", //set request type to Position
                        headers: {
                            "Content-type": 'application/json; charset=utf-8', //set specific content type
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        },
                    },
                    ajaxParams: {
                        "party_id": print_filters.party_id == "0" ? "" : window.btoa(print_filters.party_id),
                        "watt_id": print_filters.watt_id == "0" ? "" : window.btoa(print_filters.watt_id),
                        "pannel_type": print_filters.pannel_type == "0" ? "" : window.btoa(print_filters.pannel_type),
                        "status": print_filters.status == "0" ? "" : window.btoa(print_filters.status),
                        "request_code": print_filters.request_code == "" ? "" : window.btoa(print_filters.request_code),
                        "s_date": "",
                        // "s_date": print_filters.s_date == "" ? "" : window.btoa(print_filters.s_date),
                        "e_date": "",
                        // "e_date": print_filters.e_date == "" ? "" : window.btoa(print_filters.e_date),
                    },
                    pagination: 'remote',
                    paginationSize: 20,
                    // renderHorizontal:"virtual",
                    paginationSizeSelector: [20, 30, 40, 60],
                    ajaxFiltering: true,
                    ajaxSorting: true,
                    headerSort: false,
                    printAsHtml: true,
                    printStyled: true,
                    layout: 'fitColumns',
                    responsiveLayout: 'collapse',
                    placeholder: 'No matching records found',
                    columns: [

                        // For HTML table
                        {
                            title: 'OW NO',
                            minWidth: 150,
                            responsive: 0,
                            field: 'request_code',
                            hozAlign: 'left',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter: "textarea",
                            cellClick: function (e, cell) {
                                router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                            }
                        },
                        // {
                        //     title: 'Party',
                        //     minWidth: 100,
                        //     responsive: 0,
                        //     field: 'companey_name',
                        //     hozAlign: 'left',
                        //     vertAlign: 'left',
                        //     print: false,
                        //     download: false,
                        //     formatter: "textarea",
                        //     cellClick: function (e, cell) {
                        //         router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                        //     }
                        // },
                        {
                            title: 'Watt',
                            minWidth: 150,
                            responsive: 0,
                            field: 'watt',
                            hozAlign: 'left',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter: "textarea",
                            cellClick: function (e, cell) {
                                router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                            }
                        },
                        {
                            title: 'Type',
                            minWidth: 100,
                            responsive: 0,
                            field: 'product_type',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return cell.getData().product_type == 1 ? "Poly" : (cell.getData().product_type == 2 ? "MBB" : 'Both')
                            },
                            cellClick: function (e, cell) {
                                router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                            }
                        },
                        {
                            title: 'Request <br> Modules',
                            minWidth: 100,
                            responsive: 0,
                            field: 'quantity',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return cell.getData().quantity
                            },
                            cellClick: function (e, cell) {
                                router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                            }
                        },
                        {
                            title: 'Final <br> Modules',
                            minWidth: 100,
                            responsive: 0,
                            field: 'quantity',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return cell.getData().final_quantity ? cell.getData().final_quantity : (cell.getData().active_status != 1 ? cell.getData().quantity : '-')
                            },
                            cellClick: function (e, cell) {
                                router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                            }
                        },
                        {
                            title: 'Request Date',
                            minWidth: 150,
                            responsive: 0,
                            field: 'issue_date',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter: "textarea",
                            cellClick: function (e, cell) {
                                router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                            }
                        },
                        {
                            title: 'Status',
                            minWidth: 100,
                            field: 'active_status',
                            hozAlign: 'left',
                            vertAlign: 'left',
                            print: false,
                            download: false,
                            formatter(cell) {
                                if (cell.getData().active_status == 1) {

                                    return '<div class="flex items-center lg:justify-center text-theme-1">Pending</div>'

                                } else if (cell.getData().active_status == 2 && cell.getData().duty_status == 1) {

                                    return '<div class="flex items-center lg:justify-center text-theme-3">Accepted</div>'

                                } else if (cell.getData().active_status == 2 && cell.getData().duty_status == 2) {

                                    return '<div class="flex items-center lg:justify-center text-theme-3">Duty Requested</div>'

                                } else if (cell.getData().active_status == 3) {

                                    return '<div class="flex items-center lg:justify-center text-theme-6">Cancelled</div>'

                                } else if (cell.getData().active_status == 4) {

                                    return '<div class="flex items-center lg:justify-center text-theme-9">Duty Paid</div>'
                                } else if (cell.getData().active_status == 5) {

                                    return '<div class="flex items-center lg:justify-center text-theme-9">Delivered</div>'
                                }
                            }
                        },

                    ],
                    renderComplete() {
                        feather.replace({
                            'stroke-width': 1.5
                        })
                    }
                })
            }

            sessionStorage.setItem('stock_department_requset_filters', JSON.stringify(outward_filters))
        }

        const getProductDetails = async (id, type) => {
            // console.log(id);
            const params = {
                "purchase_id": window.btoa(id)
            };

            let api_url = ""
            if (type == 1) {

                api_url = "api/purchase/get_import_purchase_single_item"
            } else {

                api_url = "api/purchase/get_dta_purchase_single_item"
            }
            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    pagedata.purchase_item = data.items;

                    if (type == 1) {

                        cash('#import_purchase-details-modal').modal('show')
                    } else {

                        cash('#dta_purchase-details-modal').modal('show')
                    }
                })
        }

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // Get Request Product Details
        const request_products = async (id) => {

            const params = {
                id: window.btoa(id)
            };

            cash('#product-details-modal').modal('show')
            let promise = axios({
                url: "api/get_single_request_details",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.request_details.request_item = data.request
                    pagedata.request_details.new_quantity = pagedata.request_details.request_item.final_quantity
                    pagedata.request_details.request_products = data.items

                })
                .catch(_error => {

                });
        }

        // Update Quantity
        const update_quantity = async (item) => {

            let remain_qty = (pagedata.product_details.required_quantity - pagedata.product_details.selected_quantity)

            if (item.cr_qty >= remain_qty) {
                item.cr_new_qty = remain_qty
            } else {
                item.cr_new_qty = item.cr_qty
            }
            calculate_update_quantity()
        }

        const calculate_update_quantity = () => {

            if (pagedata.product_details.purchase_details.length > 0) {

                pagedata.product_details.selected_quantity = 0
                pagedata.product_details.purchase_details.forEach(element => {
                    if (element.selected == true) {
                        pagedata.product_details.selected_quantity += $h.myFloat(element.cr_new_qty)
                    }
                });

                if (pagedata.product_details.selected_quantity >= pagedata.product_details.required_quantity) {
                    pagedata.product_details.limit_touch = true
                } else {
                    pagedata.product_details.limit_touch = false
                }
            }
        }

        // Get Request Product Details New
        const request_products_new = async (id, final_quantity) => {

            if (isNaN(final_quantity) || final_quantity == '') {

            } else {

                pagedata.request_details.new_quantity = final_quantity

                const params = {
                    id: window.btoa(id),
                    final_quantity: window.btoa(final_quantity)
                };

                let promise = axios({
                    url: "api/get_single_request_details_new",
                    method: "POST",
                    data: params,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        pagedata.request_details.request_item = data.request
                        pagedata.request_details.request_products = data.items

                    })
                    .catch(_error => {

                    });
            }
        }

        // Get Product Details
        const sizeDetails = async () => {

            const params = {};

            let promise = axios({
                url: "api/master/dp_size",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.watt_details = data.items

                })
                .catch(_error => {

                    pagedata.watt_details = []

                });
        }

        // Get Material Details
        const partyDetails = async () => {

            const params = {};

            let promise = axios({
                url: "api/master/dp_outward_party",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.party_details = data.items

                })
                .catch(_error => {

                    pagedata.party_details = []

                });
        }

        // On reset filter
        const resetStockFilters = () => {
            outward_filters.party_id = "0"
            outward_filters.watt_id = "0"
            outward_filters.pannel_type = "0"
            outward_filters.status = "0"
            outward_filters.request_code = ""
            outward_filters.s_date = moment().startOf('month').format('YYYY-MM-DD')
            outward_filters.e_date = moment().format('YYYY-MM-DD')
            outward_filters.page_size = 1

            initTabulator()
        }

        // Accept Direct All request
        const acceptDirectAll = async () => {
            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Accept Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let new_url = "api/accept_request_direct_all"

                    pagedata.request_loading = true

                    const params = {
                        "request_item": pagedata.request_details.request_item,
                        "product_items": pagedata.request_details.request_products,
                        "new_quantity": pagedata.request_details.new_quantity,
                    };

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {

                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                pagedata.request_loading = false
                                return;
                            }

                            swal({
                                title: "Success!",
                                icon: "success",
                                text: data.message,
                                type: "success",
                                confirmButtonColor: "#064e3b",
                            })

                            request_products_new(pagedata.request_details.request_item.id, pagedata.request_details.new_quantity)
                            initTabulator()
                            // cash('#product-details-modal').modal('hide')

                            pagedata.request_loading = false
                        })
                        .catch(_error => {

                            pagedata.request_loading = false

                        });
                }
            });
        }

        // Accept Direct All request
        const rejectDirectAll = async () => {

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Accept Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let new_url = "api/reject_request_direct_all"

                    const params = {
                        "id": window.btoa(pagedata.request_details.request_item.id),
                    };

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {

                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                return;
                            }

                            swal({
                                title: "Success!",
                                icon: "success",
                                text: data.message,
                                type: "success",
                                confirmButtonColor: "#064e3b",
                            })

                            initTabulator()
                            if (pagedata.request_details.request_item.active_status == 2 && pagedata.request_details.request_item.duty_status == 1) {
                                request_products(pagedata.request_details.request_item.id)
                            } else {
                                cash('#product-details-modal').modal('hide')
                            }

                        })
                        .catch(_error => {

                        });
                }
            });
        }

        // Accept Direct All request
        const rejectDutyDirectAll = async () => {

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Accept Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let new_url = "api/cancel_duty_request_direct_all"

                    const params = {
                        "id": window.btoa(pagedata.request_details.request_item.id),
                    };

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {

                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                return;
                            }

                            swal({
                                title: "Success!",
                                icon: "success",
                                text: data.message,
                                type: "success",
                                confirmButtonColor: "#064e3b",
                            })

                            initTabulator()
                            cash('#product-details-modal').modal('hide')

                        })
                        .catch(_error => {

                        });
                }
            });
        }

        // Sent Duty Request Direct All request
        const sendDutyRequest = async () => {

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Accept Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let new_url = "api/send_duty_request_direct_all"

                    pagedata.request_loading = true

                    const params = {
                        "id": window.btoa(pagedata.request_details.request_item.id),
                    };

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {

                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                pagedata.request_loading = false
                                return;
                            }

                            if (data.items.length > 0) {
                                pagedata.duty_assigned_arr = data.items
                                setTimeout(() => {
                                    cash('#duty-request-success-modal').modal('show')

                                }, 100);
                                pagedata.request_loading = false
                                request_products_new(pagedata.request_details.request_item.id, pagedata.request_details.new_quantity)
                                initTabulator()
                                return
                            }
                            swal({
                                title: "Success!",
                                icon: "success",
                                text: data.message,
                                type: "success",
                                confirmButtonColor: "#064e3b",
                            })

                            request_products_new(pagedata.request_details.request_item.id, pagedata.request_details.new_quantity)
                            initTabulator()
                            cash('#product-details-modal').modal('hide')

                            pagedata.request_loading = false
                        })
                        .catch(_error => {

                            pagedata.request_loading = false

                        });
                }
            });

        }

        // Get Purchase Details For Select Purchase
        const request_product_purchase_single = async (id, quantity) => {
            const params = {
                id: window.btoa(id)
            };

            cash('#purchase-details-modal').modal('show')
            let promise = axios({
                url: "api/get_out_purchase_details",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.product_details.product_detail = data.product_item
                    pagedata.product_details.required_quantity = quantity
                    pagedata.product_details.purchase_details = data.items
                    pagedata.product_details.total = data.total

                    if (pagedata.product_details.purchase_details.length > 0) {
                        update_quantity()
                    }

                })
                .catch(_error => {

                });
        }

        // Update Single Item
        const add_single_product_request_quantity = async () => {

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Add Product Purchase!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let purchase_arr = []

                    let accessable_qty = pagedata.product_details.selected_quantity
                    if (pagedata.product_details.required_quantity < pagedata.product_details.selected_quantity) {

                        swal({
                            title: "Warning!",
                            icon: "warning",
                            text: "Selected Quantity is Greater then Required Quantity",
                            type: "warning",
                            confirmButtonColor: "#064e3b",
                        })

                        return
                    }

                    if (pagedata.request_details.request_item.active_status == 2 && pagedata.request_details.request_item.duty_status == 1) {
                        if (pagedata.product_details.required_quantity > pagedata.product_details.selected_quantity) {

                            swal({
                                title: "Warning!",
                                icon: "warning",
                                text: "Selected Quantity is Less then Required Quantity",
                                type: "warning",
                                confirmButtonColor: "#064e3b",
                            })

                            return
                        }
                    }

                    pagedata.product_details.purchase_details.forEach(element => {
                        if (element.selected == true) {
                            purchase_arr.push(element)
                        }
                    });

                    let new_url = "api/set_single_product_purchase_details"

                    pagedata.purchase_loading = true

                    const params = {
                        "request_item_main": pagedata.request_details.request_item,
                        "product_items": pagedata.request_details.request_products,
                        "new_quantity": pagedata.request_details.new_quantity,
                        "request_item": pagedata.product_details.product_detail,
                        "purchase_items": purchase_arr,
                        "required_quantity": pagedata.product_details.required_quantity,
                        "accessable_quantity": accessable_qty,
                    };

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {

                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                pagedata.purchase_loading = false
                                return;
                            }

                            swal({
                                title: "Success!",
                                icon: "success",
                                text: data.message,
                                type: "success",
                                confirmButtonColor: "#064e3b",
                            })

                            request_products_new(params.request_item_main.id, params.new_quantity)
                            cash('#purchase-details-modal').modal('hide')

                            pagedata.purchase_loading = false
                        })
                        .catch(_error => {

                            pagedata.purchase_loading = false

                        });
                }
            });

        }

        // Add Request To New Department
        const send_request_to_stock_department = async () => {

            if (form_data.value.length == 0) {
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fields are Empty!",
                    type: "warning",
                    confirmButtonColor: "#064e3b",
                })
                return
            }
            // Validate Fileds
            let purchase_detail_length = form_data.value.length

            let is_empty = false

            for (let i = 0; i < purchase_detail_length; i++) {

                if (form_data.value[i].watt_id == '0') {
                    $('#watt_id_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#watt_id_' + i).removeClass('border border-theme-6');
                }
                if (form_data.value[i].pannel_type == '0') {
                    $('#pannel_type_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#pannel_type_' + i).removeClass('border border-theme-6');
                }
                if (form_data.value[i].quantity == '' || form_data.value[i].quantity == null) {
                    $('#quantity_' + i).addClass('border-theme-6');
                    is_empty = true;
                } else {
                    $('#quantity_' + i).removeClass('border-theme-6');
                }
            }

            if (is_empty == true) {
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fileds Are Empty!",
                    type: "warning",
                    confirmButtonColor: "#064e3b",
                })
                return
            }

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Send Request to Stock Department!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    pagedata.submit = true

                    const params = {
                        "items": form_data.value
                    };

                    let promise = axios({
                        url: "api/set_outward_request",
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {
                                swal.fire({
                                    icon: "success",
                                    title: "Success!",
                                    text: data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                })
                                pagedata.submit = false
                                // cash('#product-details-modal').modal()
                                cash('#product-details-modal').modal('hide')

                                form_data.value = [{
                                  id: 1,
                                  update_id: '',
                                  party_id: '0',
                                  party_name: '',
                                  watt_id: '0',
                                  watt: '',
                                  pannel_type: '0',
                                  quantity: '',
                                  new_quantity: '',
                                  product_details: []
                                }]
                                initTabulator()

                            } else {
                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                pagedata.submit = false

                            }
                        })
                        .catch(_error => {
                            pagedata.submit = false

                        });

                }
            });

        }

        // Add Product Field
        const add_new_request_field = async () => {

            pagedata.count_ids += 1;
            form_data.value.push({
                id: pagedata.count_ids,
                update_id: '',
                party_id: '0',
                party_name: '',
                watt_id: '0',
                watt: '',
                pannel_type: '0',
                quantity: '',
                new_quantity: '',
                product_details: []
            });

        }

        // Get Consumption Details
        const get_product_consumption_details = async (item) => {

            if (item.party_id != "0" && item.party_name == "") {
                pagedata.party_items.forEach(element => {
                    if (element.id == item.party_id) {
                        item.party_name = element.companey_name
                    }
                });
            }

            // Check if Fields is Empty or Not
            if (item.watt_id == "0" && item.pannel_type == "0") {

            } else {

                if (item.watt_id != "0" && item.watt == "") {
                    pagedata.watt_details.forEach(element => {
                        if (element.id == item.watt_id) {
                            item.watt = element.watt
                        }
                    });
                }

                // Get The Consumption Products

                let params = {
                    watt_id: window.btoa(item.watt_id),
                    pannel_type: window.btoa(item.pannel_type)
                }

                let promise_url = "api/master/get_single_consumption_details"

                let promise = axios({
                    url: promise_url,
                    method: "POST",
                    data: params,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {
                        item.product_details = data.items

                        if (item.quantity != '' && item.product_details.length > 0) {
                            calculate_quantity_items(item)
                        }
                    })
                    .catch(_error => {
                        item.product_details = []

                    });

            }
        }

        // Calculate Product Quantity
        const calculate_quantity_items = async (item) => {

            if (item.product_details.length > 0 && item.watt_id != "0" && item.quantity != '') {

                // Calculate Product Quantity

                if (isNaN(item.quantity)) {
                    swal({
                        title: "Warning!",
                        icon: "warning",
                        text: "Please Enter a valid Quantity",
                        type: "warning",
                        confirmButtonColor: "#064e3b",
                    })
                    item.quantity = ""
                    return
                }

                item.product_details.forEach(element => {
                    element.request_quantity = $h.myFloat(item.quantity * element.quantity)
                });
            }

        }

        // Remove Request From Pannel
        const remove_request_field = async (item) => {

            swal({
                title: "Are you sure?",
                text: "You are about to delete a item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",
                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = form_data.value.length;
                    while (i--) {
                        if (form_data.value[i]["id"] == item.id) {
                            form_data.value.splice(i, 1);
                            form_data.value = form_data.value.filter(function () {
                                return true;
                            });
                            console.warn(form_data.value)
                            break
                        }
                    }
                }
            });

        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'data.xlsx', {
                sheetName: 'Products'
            })
        }

        const outward_module_request = async () => {
            swal({
                title: "Are you sure?",
                text: "You want to Deliver to the Party!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    if (pagedata.ref_no == "") {
                        swal({
                            title: "Warning!",
                            icon: "warning",
                            text: "Please Enter Invoice Number First.",
                            type: "warning",
                            confirmButtonColor: "#064e3b",
                        })
                        // swal("Warning!", "Please Enter OOC Number First.", "warning");
                    } else {

                        let new_url = "api/outward_request_direct_all"

                        pagedata.request_loading = true

                        const params = {
                            id: window.btoa(pagedata.deliver_id),
                            ref_no: pagedata.ref_no == "" ? "" : window.btoa(pagedata.ref_no)
                        };

                        let promise = axios({
                            url: new_url,
                            method: "POST",
                            data: params,
                            baseURL: BASE_URL,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token")
                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            }
                        });
                        return promise
                            .then(result => result.data)
                            .then(data => {

                                if (data.error == "YES") {

                                    swal({
                                        title: "Warning!",
                                        icon: "warning",
                                        text: data.message,
                                        type: "warning",
                                        confirmButtonColor: "#064e3b",
                                    })
                                    pagedata.request_loading = false
                                    return;
                                }

                                swal({
                                    title: "Success!",
                                    icon: "success",
                                    text: data.message,
                                    type: "success",
                                    confirmButtonColor: "#064e3b",
                                })

                                initTabulator()

                                cash('#reference-number-modal').modal('hide')

                                pagedata.request_loading = false
                            })
                            .catch(_error => {

                                pagedata.request_loading = false

                            });
                    }
                }
            });

        }
        const openModal = (id) => {
            pagedata.ref_no = ''
            pagedata.deliver_id = id
            cash('#reference-number-modal').modal('show')
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        onMounted(() => {

            let filter_items = sessionStorage.getItem('stock_department_requset_filters') != null ? JSON.parse(sessionStorage.getItem('stock_department_requset_filters')) : null

            if (filter_items != null) {
                outward_filters.party_id = filter_items.party_id
                outward_filters.watt_id = filter_items.watt_id
                outward_filters.pannel_type = filter_items.pannel_type
                outward_filters.status = filter_items.status
                outward_filters.request_code = filter_items.request_code
                outward_filters.s_date = filter_items.s_date
                outward_filters.e_date = filter_items.e_date
                outward_filters.page_size = filter_items.page_size

            }

            sizeDetails()
            partyDetails()
            initTabulator()
            reInitOnResizeWindow()
        })

        return {
            tableRef,
            pagedata,
            outward_filters,
            print_filters,
            form_data,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            initTabulator,
            request_products_new,
            resetStockFilters,
            acceptDirectAll,
            rejectDirectAll,
            rejectDutyDirectAll,
            sendDutyRequest,
            request_product_purchase_single,
            update_quantity,
            add_single_product_request_quantity,
            getProductDetails,
            calculate_update_quantity,
            userDetails,
            outward_module_request,
            send_request_to_stock_department,
            add_new_request_field,
            get_product_consumption_details,
            calculate_quantity_items,
            remove_request_field,
            openModal
        }
    }
})
</script>

<style scoped>
.tableFixHead {
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    max-height: 700px;
    /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}
</style>
