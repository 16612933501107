/* eslint-disable */
const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'dashboard-view',
        title: 'Dashboard',
        roles:[1,2,3,4]
      },
      {
        icon: 'HomeIcon',
        pageName: 'purchase',
        title: 'Purchase',
        roles:[1,2],
        subMenu: [
          {
            icon: '',
            pageName: 'import-purchase-view',
            title: 'Import',
            roles:[1,2]
          },
          {
            icon: '',
            pageName: 'dta-purchase-view',
            title: 'DTA',
            roles:[1,2]
          }
        ]
      },
      {
        icon: 'InboxIcon',
        pageName: 'stock-consumption-details',
        title: 'Est Module Stock',
        roles:[1,2,3,4]
      },
      {
        icon: 'InboxIcon',
        pageName: 'purchase-stock-list',
        title: 'Fail Material',
        roles:[1,2,4]
      },
      {
        icon: 'HomeIcon',
        pageName: 'account-dept',
        title: 'Accounts',
        roles:[1,4],
        subMenu: [
          {
            icon: '',
            pageName: 'direct-duty-request-view',
            title: 'Direct Duty',
            roles:[1,4]
          },
          {
            icon: '',
            pageName: 'custom-duty-request-view',
            title: 'Custom Duty',
            roles:[1,4]
          }
        ]
      },
      {
        icon: 'HomeIcon',
        pageName: 'stock-dept-create-outward-request-list',
        title: 'OW Requests',
        roles:[1,3]
      },
      {
        icon: 'SidebarIcon',
        pageName: 'outward-stock-list',
        title: 'Module Stock',
        roles:[1,3]
      },
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'create-outward-requests',
      //   title: 'OW',
      //   roles:[1,2,3],
      //   subMenu: [
      //     {
      //       icon: '',
      //       pageName: 'create-outward-request',
      //       title: 'Create Request',
      //       roles:[1,2,3]
      //     },
      //     {
      //       icon: '',
      //       pageName: 'stock-dept-create-outward-request-list',
      //       title: 'Request List',
      //       roles:[1,2,3]
      //     },
      //     {
      //       icon: '',
      //       pageName: 'create-outward-request-list',
      //       title: 'Module OW',
      //       roles:[1,2,3]
      //     }
      //   ]
      // },
      {
        icon: 'HomeIcon',
        pageName: 'report-master',
        title: 'Reports',
        roles:[1,2,3,4]
      },
      {
        icon: 'BoxIcon',
        pageName: 'master-view',
        title: 'Master',
        roles:[1,2]
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
